import EmojiSadColoredIcon from 'components/Icons/EmojiSadColored';
import EmojiMehColoredIcon from 'components/Icons/EmojiMehColored';
import EmojiSmileColoredIcon from 'components/Icons/EmojiSmileColored';
import EmojiHappyColoredIcon from 'components/Icons/EmojiHappyColored';
import styled from '@emotion/styled/macro';
import EmojiThumbsDownColoredIcon from 'components/Icons/EmojiThumbsDownColored';
import EmojiThumbsUpColoredIcon from 'components/Icons/EmojiThumbsUpColored';

const getStyledIcon = (Icon, color) => styled(Icon)`
  color: ${color};
`;

const facesChoices = {
  bad: {
    Emoji: getStyledIcon(EmojiSadColoredIcon, '#F43756'),
    value: 'bad',
    color: '#F43756',
  },
  notGood: {
    Emoji: getStyledIcon(EmojiMehColoredIcon, '#FFE17D'),
    value: 'not_good',
    color: '#FFE17D',
  },
  good: {
    Emoji: getStyledIcon(EmojiSmileColoredIcon, '#A0CC7C'),
    value: 'good',
    color: '#A0CC7C',
  },
  great: {
    Emoji: getStyledIcon(EmojiHappyColoredIcon, '#4DAF4F'),
    value: 'great',
    color: '#4DAF4F',
  },
};

const facesActiveChoices = [facesChoices.bad, facesChoices.notGood, facesChoices.good, facesChoices.great];

const thumbsChoices = {
  thumbsDown: {
    Emoji: getStyledIcon(EmojiThumbsDownColoredIcon, '#F43756'),
    value: 'thumbs_down',
    color: '#FF878A',
  },
  thumbsUp: {
    Emoji: getStyledIcon(EmojiThumbsUpColoredIcon, '#4DAF4F'),
    value: 'thumbs_up',
    color: '#5083f1',
  },
};

const thumbsActiveChoices = [thumbsChoices.thumbsDown, thumbsChoices.thumbsUp];

export default {
  getChoice: (value, emojiType = 'faces') => (emojiType === 'faces' ? facesChoices[value] : thumbsChoices[value]),
  getActiveChoices: (emojiType = 'faces') => (emojiType === 'faces' ? facesActiveChoices : thumbsActiveChoices),
};
