import { all, call } from 'redux-saga/effects';
import analyticsSagas from './analytics/sagas';
import authSagas from './auth/sagas';
import birthdaySagas from './birthday/sagas';
import boostSagas from './boost/sagas';
import calendarSagas from './calendar/sagas';
import contactSagas from './contact/sagas';
import courseSagas from './course/sagas';
import learningAnalyticsSagas from './learningAnalytics/sagas';
import lessonSagas from './lesson/sagas';
import questionSagas from './question/sagas';
import driveSagas from './drive/sagas';
import trainingSagas from './training/sagas';
import challengeSagas from './challenge/sagas';
import channelSagas from './channel/sagas';
import chatSagas from './chat/sagas';
import commentSagas from './comment/sagas';
import contractSagas from './contract/sagas';
import fileSagas from './file/sagas';
import groupSagas from './group/sagas';
import guidanceSagas from './guidance/sagas';
import integrationSagas from './integration/sagas';
import jobAnniversarySagas from './jobAnniversary/sagas';
import memberSagas from './member/sagas';
import pageSagas from './page/sagas';
import pulseSagas from './pulse/sagas';
import pollSagas from './poll/sagas';
import postSagas from './post/sagas';
import quizSagas from './quiz/sagas';
import userSagas from './user/sagas';
import verificationSagas from './verification/sagas';
import workspaceSagas from './workspace/sagas';
import feedbackSagas from './feedback/sagas';
import documentFolderSagas from './documentFolder/sagas';
import documentSectionSagas from './documentSection/sagas';

export default function* IndexSaga() {
  yield all([
    call(analyticsSagas),
    call(authSagas),
    call(birthdaySagas),
    call(boostSagas),
    call(calendarSagas),
    call(challengeSagas),
    call(channelSagas),
    call(chatSagas),
    call(commentSagas),
    call(contactSagas),
    call(contractSagas),
    call(courseSagas),
    call(documentFolderSagas),
    call(documentSectionSagas),
    call(driveSagas),
    call(feedbackSagas),
    call(fileSagas),
    call(groupSagas),
    call(guidanceSagas),
    call(integrationSagas),
    call(jobAnniversarySagas),
    call(learningAnalyticsSagas),
    call(lessonSagas),
    call(memberSagas),
    call(pageSagas),
    call(pulseSagas),
    call(pollSagas),
    call(postSagas),
    call(questionSagas),
    call(quizSagas),
    call(trainingSagas),
    call(userSagas),
    call(verificationSagas),
    call(workspaceSagas),
  ]);
}
