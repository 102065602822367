import React, { useEffect } from 'react';
import Post from 'containers/Post/Single/Post';
import TextLoader from 'components/TextLoader';
import InfiniteScroll from 'react-infinite-scroller';
import PropTypes from 'prop-types';
import EmptyState from 'components/EmptyState';
import EndOfSomething from 'components/EndOfSomething';
import { useTranslation } from 'react-i18next';
import { authSelectors, authActions } from 'redux/auth';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled/macro';
import Button from 'components/Button';
import Grid from 'components/Grid';
import PulseForm from 'containers/Pulse/PulseForm';
import ActivePulseSurveyForm from 'containers/Pulse/ActivePulseSurveyForm';
import Feed from 'components/Feed';
import { postActions, postSelectors } from 'redux/post';
import usePrevious from 'hooks/usePrevious';
import ScheduledPosts from './ScheduledPosts';

const NarrowEmptyState = styled(EmptyState)`
  padding-top: 0rem;
  padding-bottom: 0rem;
`;

const normalPayload = { includeDrafts: 1, includePosted: 1, includeScheduled: 0 };
const scheduledPayload = { includeDrafts: 0, includePosted: 0, includeScheduled: 1, sort: 'scheduled_at', sortDirection: 'asc' };

const PostFeed = ({ feedType, feedId, payload, emptyStateTitle, emptyStateSubtitle, ...rest }) => {

  const { t } = useTranslation(['component']);
  const workspace = useSelector(authSelectors.getWorkspace);
  const dispatch = useDispatch();

  const feedName = feedId ? `${feedType}-${feedId}` : feedType;

  useEffect(() => {
    dispatch(postActions.getFeed({ feedName, feedType, feedId, page: 1, ...normalPayload, ...payload }));
    dispatch(postActions.getFeed({ feedName: `${feedName}-scheduled`, feedType, feedId, page: 1, ...scheduledPayload, ...payload }));
  }, [dispatch, feedId, feedName, feedType, payload]);

  const { postIds, pagination } = useSelector(postSelectors.getFeedSelector)(feedName);
  const { postIds: scheduledPostIds, pagination: scheduledPagination } = useSelector(postSelectors.getFeedSelector)(`${feedName}-scheduled`);

  const loadMore = () => dispatch(postActions.getFeed({ feedName, feedType, feedId, page: pagination.nextPage, ...normalPayload, ...payload }));
  const loadMoreScheduled = () => dispatch(postActions.getFeed({ feedName: `${feedName}-scheduled`, feedType, feedId, page: scheduledPagination.nextPage, ...scheduledPayload, ...payload }));

  // Manually fetch new list for scheduled posts whenever the feed gets reset
  const previousScheduledPagination = usePrevious(scheduledPagination);
  useEffect(() => {
    if (previousScheduledPagination?.total !== undefined && scheduledPagination?.total === undefined) {
      dispatch(postActions.getFeed({ feedName: `${feedName}-scheduled`, feedType, feedId, page: 1, ...scheduledPayload, ...payload }));
    }
  }, [dispatch, feedId, feedName, feedType, payload, previousScheduledPagination, scheduledPagination]);

  const logout = () => dispatch(authActions.logout());

  const emptyState = {
    title: emptyStateTitle || t('postFeed.noPosts'),
    subtitle: emptyStateSubtitle,
  };

  if (!workspace) {
    return <NarrowEmptyState title={t('postFeed.noWorkspace')} subtitle={<Button onClick={logout}>{t('userMenu.logout')}</Button>} />;
  }

  const loaderProps = {
    loadMore,
    hasMore: pagination.hasMore,
    loader: <TextLoader key="0" text={postIds.length === 0 && pagination.hasMore ? t('postFeed.loadingPosts') : t('postFeed.loadingMorePosts')} />,
  };

  const showPulse = idx => {
    // For example show after 3rd post and then every +15 after that
    const showAfter = 2;
    const thenAfter = 15;

    if (postIds.length <= showAfter) {
      // Show after last.
      return idx === postIds.length - 1;
    }

    return (idx + 1) % thenAfter === showAfter;
  };

  return (
    <>
      <InfiniteScroll {...loaderProps} {...rest}>
        <Grid as={Feed} container direction="column" wrap="nowrap" spacing={2}>
          {!!scheduledPostIds?.length && (
            <Grid item>
              <ScheduledPosts
                postIds={scheduledPostIds}
                hasMore={scheduledPagination.hasMore}
                loadMore={loadMoreScheduled}
                total={scheduledPagination.total}
              />
            </Grid>
          )}
          {postIds.map((postId, idx) => (
            <React.Fragment key={postId}>
              <Grid item><Post postId={postId} isCompact feedName={feedName} /></Grid>
              {showPulse(idx) && <Grid item component={PulseForm} />}
              {showPulse(idx) && <Grid item component={ActivePulseSurveyForm} />}
            </React.Fragment>
          ))}
          {!pagination.hasMore && postIds.length === 0 && <Grid item><EmptyState {...emptyState} /></Grid>}
          {!pagination.hasMore && postIds.length > 0 && <Grid item><EndOfSomething>{t('postFeed.endOfPosts')}</EndOfSomething></Grid>}
        </Grid>
      </InfiniteScroll>
    </>
  );
};

PostFeed.propTypes = {
  feedType: PropTypes.string.isRequired,
  feedId: PropTypes.any,
  payload: PropTypes.object,

  emptyStateTitle: PropTypes.string,
  emptyStateSubtitle: PropTypes.string,
};

export default PostFeed;
