import memoize from 'lodash/memoize';
import { selectPagination } from 'redux/helpers/pagination';
import { createSelector } from 'reselect';

const getPulseSurveyEntities = ({ entities: { pulseSurveys } }) => pulseSurveys;
const getPulseSurveySelector = createSelector([getPulseSurveyEntities], entities => memoize(id => entities?.[id]));
const getPulseSurveysPagination = ({ pulse: { allPulseSurveys } }) => selectPagination(allPulseSurveys, null, 'pulseSurveyIds');
const getPulseSurveys = createSelector(
  [getPulseSurveysPagination, getPulseSurveySelector],
  ({ pulseSurveyIds }, getPulseSurvey) => pulseSurveyIds.map(getPulseSurvey),
);

const getPulseSurveyResponseEntities = ({ entities: { pulseSurveyResponses } }) => pulseSurveyResponses;
const getPulseSurveyResponseSelector = createSelector([getPulseSurveyResponseEntities], entities => memoize(id => entities?.[id]));
const getPulseSurveyResponsesPagination = ({ pulse: { allPulseSurveyResponses } }) => selectPagination(allPulseSurveyResponses, null, 'pulseSurveyResponseIds');
const getPulseSurveyResponses = createSelector(
  [getPulseSurveyResponsesPagination, getPulseSurveyResponseSelector],
  ({ pulseSurveyResponseIds }, getPulseSurveyResponse) => pulseSurveyResponseIds.map(getPulseSurveyResponse),
);

const getPulseSurveyOverviewAnalyticsEntities = ({ entities: { pulseSurveysOverviewAnalytics } }) => pulseSurveysOverviewAnalytics;
const getPulseSurveyOverviewAnalyticsSelector = createSelector([getPulseSurveyOverviewAnalyticsEntities], entities => memoize(id => entities?.[id]));

const getPulseScalesEntities = ({ entities: { pulseScales } }) => pulseScales;
const getPulseScales = createSelector([getPulseScalesEntities], pulseScales => Object.values(pulseScales || {}));

export default {
  getPulseSurveySelector,
  getPulseSurveysPagination,
  getPulseSurveys,

  getPulseSurveyResponseSelector,
  getPulseSurveyResponsesPagination,
  getPulseSurveyResponses,

  getPulseSurveyOverviewAnalyticsSelector,

  getPulseScales,
};
