import React, { Suspense } from 'react';

import { Switch, Redirect, Route } from 'react-router-dom';
import AuthRoute from 'components/AuthRoute';
import { isMobile } from 'helpers';
import TextLoader from 'components/TextLoader';
import SnackbarDisplayer from 'components/SnackbarDisplayer';

import useTrackers from 'providers/useTrackers';

import Side from 'containers/Side/Side';
import useNavigationItems from 'containers/Sidebar/useNavigationItems';
import Providers from './Providers';

import WebDefaultLayout from './Layouts/DefaultLayout/WebDefaultLayout';
import Home from './Home';

const Preferences = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'preferences' */ './Preferences'));

const Menu = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'mobile' */ './Menu'));
const Notifications = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'mobile' */ './Notifications'));
const PostCreate = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'mobile' */ './Post/Create'));
const PostEdit = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'mobile' */ './Post/Edit'));
const ContactDirectory = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'mobile' */ './ContactDirectory/ContactDirectory'));
const ManageContacts = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'mobile' */ './ContactDirectory/ManageContacts'));
const ActivityEdit = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'mobile' */ './Activity/Edit'));
const ActivityCreate = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'mobile' */ './Activity/Create'));

const Post = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './Post/Show'));
const NewsFeed = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './NewsFeed'));
const Groups = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './Group/Groups'));
const Group = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './Group/Group'));
const Feedback = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './Feedback/Feedback'));
const Calendar = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './Calendar'));
const Drive = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './Drive'));
const Course = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './Course'));
const Training = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './Training'));
const Learning = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './Learning/Learning'));
const LearningCourse = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './Learning/Course'));
const LearningCourseVariantPreview = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './Learning/CourseVariantPreview'));
const LearningLesson = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './Learning/Lesson'));
const DocumentsFolders = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './Documents/Folders'));
const DocumentsFolder = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './Documents/Folder'));
const Event = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './Event'));
const Record = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './Record'));
const Pulse = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './Feedback/Pulse'));
const CompanyProfile = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './CompanyProfile'));
const Guidance = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './Guidance'));
const Article = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './Article'));
const Challenges = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './Challenge/Challenges'));
const Challenge = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './Challenge/Show'));
const Files = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './Files'));
const Search = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './Search'));
const Member = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './Member'));
const Page = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './Page'));
const Subportals = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'secondary' */ './Subportals/Subportals'));

const Welcome = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'auth' */'./Welcome'));
const Forgot = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'auth' */ './Auth/Forgot'));
const Login = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'auth' */ './Auth/Login'));
const Register = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'auth' */ './Auth/Register'));
const Reset = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'auth' */ './Auth/Reset'));
const Join = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'auth' */ './Auth/Join'));
const PublicFeedbackForm = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'auth' */ './Public/FeedbackForm'));
const PublicFeedbackView = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'auth' */ './Public/PublicFeedbackView'));

const ManageMembers = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'admin' */ './Manage/Members/Members'));
const ManageCalendar = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'admin' */ './Manage/Calendar'));
const ManageDrive = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'admin' */ './Manage/Drive'));
const ManageLearning = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'admin' */ './Manage/Learning/Learning'));
const ManageLearningCourse = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'admin' */ './Manage/Learning/Course'));
const ManageLearningCourseAdd = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'admin' */ './Manage/Learning/AddCourse'));
const ManageLearningCourseVariantEdit = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'admin' */ './Manage/Learning/EditCourseVariant'));
const ManageLearningAnalytics = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'admin' */ './Manage/Learning/Analytics'));
const ManageDocumentsFolder = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'admin' */ './Manage/Documents/Folders'));
const ManageDocumentsFolderAdd = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'admin' */ './Manage/Documents/AddFolder'));
const ManageDocumentsFolderEdit = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'admin' */ './Manage/Documents/EditFolder'));
const ManageTraining = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'admin' */ './Manage/Training'));
const ManageSettings = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'admin' */ './Manage/Settings/Settings'));
const ManageGroups = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'admin' */ './Manage/Groups'));
const ManageChallenges = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'admin' */ './Manage/Challenges'));
const ManagePages = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'admin' */ './Manage/Pages/Pages'));
const ManagePulse = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'admin' */ './Manage/Pulse/Pulse'));
const ManageFeedback = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'admin' */ './Manage/Feedback/Feedback'));
const ManageFeedbackView = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'admin' */ './Manage/Feedback/FeedbackShow'));
const ManageAnalytics = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'admin' */ './Manage/Analytics'));
const BestPractices = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: 'admin' */ './Manage/BestPractices'));

const publicRoutes = [
  { path: '/public/feedback/:feedbackTypeId', component: PublicFeedbackForm },
  { path: '/public/feedback-sent/:feedbackId', component: PublicFeedbackView },
];

const unauthRoutes = [
  { path: '/join', component: Join },
  { path: '/login', component: Login },
  { path: '/forgot', component: Forgot },
  { path: '/reset/:token', component: Reset },
  { path: '/register/:invitationToken?', component: Register },
];

const workspaceRoutes = [

  ...(!isMobile ? [{ path: '/welcome', component: Welcome, exact: true }] : []),

  { path: '/', component: Home, exact: true },

  { path: '/preferences', component: Preferences },

  { path: '/challenges/:id', component: Challenge },
  { path: '/challenges', component: Challenges },
  { path: '/calendar/:status?', component: Calendar },
  { path: '/drive/:type?', component: Drive },
  { path: '/training/:status?', component: Training },
  { path: [
    '/e-learning/courses',
    '/e-learning/courses/completed',
    '/e-learning/team',
  ],
  component: Learning },
  { path: '/e-learning/course/:courseId/variant/:courseVariantId/preview', component: LearningCourseVariantPreview },
  { path: '/e-learning/course/:id', component: LearningCourse },
  { path: '/e-learning/lesson/:id', component: LearningLesson },
  { path: '/documents/', component: DocumentsFolders, exact: true },
  { path: '/documents/folder/:id', component: DocumentsFolder },
  { path: '/guidance/:filter?', component: Guidance },
  { path: '/article/:id', component: Article },
  { path: '/profile', component: CompanyProfile },
  { path: '/feedback/pulse', component: Pulse },
  { path: '/feedback', component: Feedback },
  { path: '/files', component: Files },
  { path: '/subportals', component: Subportals },

  { path: '/groups/:id', component: Group },
  { path: '/groups', component: Groups },
  { path: '/messages/:id?', component: NewsFeed },

  { path: '/post/create', component: isMobile ? PostCreate : null },
  { path: '/activity/create', component: isMobile ? ActivityCreate : null },
  { path: '/activity/:id/edit', component: isMobile ? ActivityEdit : null },
  { path: '/post/:id/edit', component: isMobile ? PostEdit : null },
  { path: '/post/:id', component: Post },
  { path: '/page/:id', component: Page },
  { path: '/menu', component: isMobile ? Menu : null },
  { path: '/notifications', component: isMobile ? Notifications : null },
  { path: '/directory', component: isMobile ? ContactDirectory : null },
  { path: '/contacts', component: isMobile ? ManageContacts : null },
  { path: '/member/:id', component: Member },

  { path: '/event/:id', component: Event },
  { path: '/course/:id', component: Course },
  { path: '/record/:id', component: Record },

  { path: '/search/:filter?', component: Search },
];

const ManageGenericRedirect = () => {
  const { adminItems, memberItems } = useNavigationItems();

  if (adminItems.length) {
    return <Redirect to={adminItems[0].to} />;
  }

  return <Redirect to={memberItems[0].to} />;
};

const managementRoutes = process.env.REACT_APP_BUILD_FOR !== 'mobile' ? [
  { path: '/manage/analytics', component: ManageAnalytics },
  { path: '/manage/calendar/:status?', component: ManageCalendar },
  { path: '/manage/drive/:type?', component: ManageDrive },
  { path: ['/manage/e-learning', '/manage/e-learning/training-plan'], exact: true, component: ManageLearning },
  { path: ['/manage/e-learning/course/add', '/manage/e-learning/course/:courseId/variant/add'], exact: true, component: ManageLearningCourseAdd },
  { path: '/manage/e-learning/course/:courseId/:view?', exact: true, component: ManageLearningCourse },
  { path: '/manage/e-learning/course/:courseId/variant/:courseVariantId/edit/:edit?', exact: true, component: ManageLearningCourseVariantEdit },
  { path: '/manage/e-learning/analytics/:filter?', exact: true, component: ManageLearningAnalytics },
  { path: '/manage/documents', exact: true, component: ManageDocumentsFolder },
  { path: ['/manage/documents/folder/add', '/manage/documents/folder/:folderId/variant/add'], exact: true, component: ManageDocumentsFolderAdd },
  { path: '/manage/documents/folder/:folderId/variant/:folderVariantId/edit/:edit?', exact: true, component: ManageDocumentsFolderEdit },
  { path: '/manage/training/:status?', component: ManageTraining },
  { path: '/manage/members/:filter?', component: ManageMembers },
  { path: '/manage/challenges/:filter?', component: ManageChallenges },
  { path: '/manage/pages', component: ManagePages },
  { path: '/manage/pulse-surveys', component: ManagePulse },

  { path: ['/manage/feedback', '/manage/feedback-types', '/manage/feedback-pulse'], exact: true, component: ManageFeedback },
  { path: '/manage/feedback/:id', component: ManageFeedbackView },

  { path: '/manage/groups', component: ManageGroups },
  { path: '/manage/settings', component: ManageSettings },
  { path: '/manage/practices/:filter?', component: BestPractices },
  { path: '/manage', component: ManageGenericRedirect },
] : [];

const DefaultLayoutForWeb = process.env.REACT_APP_BUILD_FOR !== 'mobile' ? WebDefaultLayout : React.Fragment;

const authRoutes = [
  ...managementRoutes,
  ...workspaceRoutes,
];

const App = () => {

  useTrackers();

  return (
    <Suspense fallback={<TextLoader delay={300} />}>

      <Switch>

        {unauthRoutes.map(route => <AuthRoute key={route.path} {...route} auth={false} />)}

        {publicRoutes.map(route => <Route key={route.path} {...route} auth={null} />)}

        <DefaultLayoutForWeb>
          <Suspense fallback={<TextLoader delay={300} />}>

            <Switch>
              {authRoutes.map(route => <AuthRoute key={route.path} {...route} />)}
              <Redirect to="/" />
            </Switch>

          </Suspense>
        </DefaultLayoutForWeb>

      </Switch>

      <Side />

      <SnackbarDisplayer />

    </Suspense>
  );
};

const AppWithProviders = () => (
  <Suspense fallback={null}>
    <Providers>
      <App />
    </Providers>
  </Suspense>
);

export default AppWithProviders;
