import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import useResize from 'hooks/useResize';
import Grid from 'components/Grid';
import fieldRenderProps from 'components/FinalForm/fieldRenderProps';
import FormField from './FormField';
import CustomFieldBase from '../../Fields/Partials/CustomFieldBase';
import RatingLabelRow from './Partials/RatingLabelRow';

const CompactFormControlLabel = styled(FormControlLabel)`
  margin: 0;

  max-width: 55px;
  width: 100%;
  min-width: 42px;
  justify-self: center;

  .MuiRadio-root {
    margin-top: -6px;
    margin-bottom: -6px;
  }

  .Mui-disabled.Mui-checked {
    color: ${({ theme }) => theme.palette.primary.main}
  }
`;

const clampedLabel = css`
  .js-overflow-label {
      line-height: 1em;
      max-height: 2em;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      justify-content: center;

      height: 2em;
      display: flex;
  }
`;

const layouts = ({
  1: css``,
  2: css`
    & > label:nth-of-type(2n) .js-overflow-label { margin-top: 1.5em; }`,
  3: css`
    & > label:nth-of-type(2n-1) .js-overflow-label { margin-top: 1.5em; }`,
  4: css`
    ${clampedLabel}
    & > label:nth-of-type(3n-1) .js-overflow-label { margin-top: 2.5em; }
    & > label:nth-of-type(3n) .js-overflow-label { margin-top: 5em; }
  `,
  5: css`
    ${clampedLabel}
    & > label:nth-of-type(3n-1) .js-overflow-label { margin-top: 5em; }
    & > label:nth-of-type(3n) .js-overflow-label { margin-top: 2.5em; }
  `,
  6: css`
    ${clampedLabel}
    & > label:nth-of-type(3n-1) .js-overflow-label { margin-top: 2.5em; } // 0.5em + 1em + 1em
    & > label:nth-of-type(3n) .js-overflow-label { margin-top: 5em; } // 0.5em + 1em + 1em + 0.5 em
  `,
  7: css`
    ${clampedLabel}
    & > label:nth-of-type(3n-1) .js-overflow-label { margin-top: 5em; }
    & > label:nth-of-type(3n) .js-overflow-label { margin-top: 2.5em; }
  `,
});
const maxLayout = Math.max(...Object.keys(layouts));
const getLayoutStyle = (layout) => layouts[layout] || getLayoutStyle(maxLayout);

const styledRadioGroupConfig = {
  shouldForwardProp: prop => prop !== 'fullWidth',
};
const StyledRadioGroup = styled(RadioGroup, styledRadioGroupConfig)`
  display: flex;
  flex-wrap: nowrap;

  ${({ fullWidth }) => fullWidth && css`
    display: flex;
    justify-content: space-between;

    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
  `}

  ${({ layout }) => getLayoutStyle(layout)}
`;

const Textlabel = styled('span')`
  line-height: 1em;
  min-width: 80px;
  display: inline-block;

  padding: 0 0.25em;
`;

const StyledLabel = styled('span')`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: 500;
  text-align: center;
  display: block;

  .Mui-checked + span & {
    font-weight: 600;
  }
`;

const Label = ({ options, optionLabel, value }) => {
  const label = options?.[value];

  if (optionLabel === 'none') { return null; }
  if (optionLabel === 'value' && (value === null || value === undefined)) { return null; }
  if (optionLabel === 'label' && (label === null || value === undefined)) { return null; }
  if (optionLabel === 'both' && (label === null || value === undefined) && (value === null || value === undefined)) { return null; }

  return (
    <StyledLabel>
      {(optionLabel === 'value' || optionLabel === 'both') && <span>{value}</span>}
      {optionLabel === 'both' && label && <br />}
      {(optionLabel === 'label' || optionLabel === 'both') && label && <Textlabel className="js-overflow-label">{label}</Textlabel>}
    </StyledLabel>
  );
};

Label.propTypes = {
  options: PropTypes.object,
  optionLabel: PropTypes.oneOf(['none', 'value', 'both', 'label']),
  value: PropTypes.number,
};

const doNodesOverlap = (a, b) => {
  const aRect = a.getBoundingClientRect();
  const bRect = b.getBoundingClientRect();

  return !(
    aRect.top > bRect.bottom
    || aRect.right < bRect.left
    || aRect.bottom < bRect.top
    || aRect.left > bRect.right
  );
};

const getHasOverlap = (nodes) => nodes && !!Array.from(nodes).find((a, idx) => (
  Array.from(nodes).slice(idx + 1).find(b => (
    doNodesOverlap(a, b)
  ))
));

export const LikertFieldComponent = ({ input: { name, value, onChange }, label, start = 1, max = 7, fullWidth = true, options, optionLabel = 'value', bottomLeftLabel, bottomRightLabel, bottomMidLabel, topLeftLabel, topRightLabel, topMidLabel, readOnly }) => {

  const ref = useRef();

  const [layout, setLayout] = useState(1);

  const checkOverlap = useCallback(() => {
    const nodes = ref.current?.querySelectorAll('.js-overflow-label');
    const hasOverlap = getHasOverlap(nodes);

    if (hasOverlap) {
      setLayout(c => c + 1);
    }
  }, []);

  useResize(() => {
    // On resize, start trying from the first layout again (1, 2, 3, ...)
    setLayout(1);
    setTimeout(() => checkOverlap(true));
  }, { passive: true });

  useLayoutEffect(() => {
    // When we reach the last layout, then stop... no more ideas
    if (layout < maxLayout) {
      checkOverlap();
    }
  }, [checkOverlap, ref, layout]);

  return (
    <CustomFieldBase label={label} margin="none">
      <Grid container spacing={1} direction="column" wrap="nowrap">

        {(topLeftLabel || topMidLabel || topRightLabel) && (
          <Grid item>
            <RatingLabelRow left={topLeftLabel} mid={topMidLabel} right={topRightLabel} />
          </Grid>
        )}

        <Grid item>
          <StyledRadioGroup row ref={ref} name={name} fullWidth={fullWidth} layout={layout} onChange={(e, v) => onChange(v)} value={value}>
            {Array.from(new Array(max)).map((v, idx) => (
              <CompactFormControlLabel
                key={idx}
                onClick={(e) => e.target.value === value ? onChange(null) : null}
                value={(start !== undefined ? start : 1) + idx}
                label={<Label options={options} optionLabel={optionLabel} value={(start !== undefined ? start : 1) + idx} />}
                labelPlacement="bottom"
                disabled={readOnly}
                control={<Radio />}
              />
            ))}
          </StyledRadioGroup>
        </Grid>

        {(bottomLeftLabel || bottomMidLabel || bottomRightLabel) && (
          <Grid item>
            <RatingLabelRow left={bottomLeftLabel} mid={bottomMidLabel} right={bottomRightLabel} />
          </Grid>
        )}
      </Grid>
    </CustomFieldBase>
  );
};

LikertFieldComponent.propTypes = {
  ...fieldRenderProps,


  max: PropTypes.number,
  start: PropTypes.number,
  fullWidth: PropTypes.bool,
  options: PropTypes.object,
  optionLabel: PropTypes.oneOf(['none', 'value', 'label', 'both']),

  bottomLeftLabel: PropTypes.string,
  bottomRightLabel: PropTypes.string,
  bottomMidLabel: PropTypes.string,

  topLeftLabel: PropTypes.string,
  topRightLabel: PropTypes.string,
  topMidLabel: PropTypes.string,
};

const LikertField = (args) => (
  <FormField component={LikertFieldComponent} {...args} />
);


export default LikertField;
