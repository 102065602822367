import { pulseTypes } from 'redux/pulse';
import merge from 'lodash/merge';

const pulseSurveysReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case pulseTypes.deletePulseSurveySuccess:
      return merge({}, state, { [payload.request.pulseSurveyId]: null });
    default:
      return state;
  }
};

export default pulseSurveysReducer;
