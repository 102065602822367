import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import styled from '@emotion/styled/macro';
import { ReactComponent as EmojiThumbsDownColoredSvg } from './assets/emoji-thumbs-down-colored.svg';

const StyledSvgIcon = styled(SvgIcon)`
  color: #FF878A;
`;

const EmojiThumbsDownColoredIcon = args => <StyledSvgIcon component={EmojiThumbsDownColoredSvg} {...args} />;

export default EmojiThumbsDownColoredIcon;
