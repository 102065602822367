import { combineReducers } from 'redux';
import { addToPagination, createPagination } from 'redux/helpers/pagination';
import types from './types';

const allPulseSurveys = (state = createPagination(null, 'pulseSurveyIds'), { type, payload }) => {
  switch (type) {
    case types.getPulseSurveysSuccess:
      return addToPagination(state, null, payload, 'pulseSurveyIds');
    default:
      return state;
  }
};

const pulseScales = (state = [], { type, payload }) => {
  switch (type) {
    case types.getPulseScalesSuccess:
      return payload.data;
    default:
      return state;
  }
};

const allPulseSurveyResponses = (state = createPagination(null, 'pulseSurveyResponseIds'), { type, payload }) => {
  switch (type) {
    case types.getPulseSurveyResponsesSuccess:
      return addToPagination(state, null, payload, 'pulseSurveyResponseIds');
    default:
      return state;
  }
};

const pulseSurveyPeriods = (state = [], { type, payload }) => {
  switch (type) {
    case types.getPulseSurveyPeriodsSuccess:
      return payload.data;
    default:
      return state;
  }
};

const pulseSurveySchedules = (state = [], { type, payload }) => {
  switch (type) {
    case types.getPulseSurveySchedulesSuccess:
      return payload.data;
    default:
      return state;
  }
};

const pulse = combineReducers({
  allPulseSurveys,
  pulseScales,
  allPulseSurveyResponses,
  pulseSurveyPeriods,
  pulseSurveySchedules,
});

export default { pulse };
