import { schema } from 'normalizr';
import pulseSurveySchema from './pulseSurvey';
import pulseSurveyResponseSchema from './pulseSurveyResponse';

const pulseSurveyOverviewAnalytics = new schema.Entity(
  'pulseSurveysOverviewAnalytics',
  {
    pulseSurvey: pulseSurveySchema,
    recentResponses: [pulseSurveyResponseSchema],
  },
  {
    idAttribute: entity => entity.pulseSurvey.id,
  },
);

export default pulseSurveyOverviewAnalytics;
