import { all, put, takeLeading } from 'redux-saga/effects';
import { api, commonApiSaga } from 'redux/helpers/api';
import { takeLeadingPerKey } from 'redux/helpers/saga';
import types from './types';
import actions from './actions';

// TODO: Unefficient?
function* onConversationMemberAdd({ payload = {}, type }) {
  yield commonApiSaga({ apiFn: api.chat.conversationMemberAdd }, { payload, type });
  yield put(actions.getConversation({ conversationId: payload.conversationId }));
  yield put(actions.getMembersForConversation({ conversationId: payload.conversationId }));
}

// TODO: Unefficient?
function* onConversationMemberDelete({ payload = {}, type }) {
  yield commonApiSaga({ apiFn: api.chat.conversationMemberDelete }, { payload, type });
  yield put(actions.getConversation({ conversationId: payload.conversationId }));
  yield put(actions.getMembersForConversation({ conversationId: payload.conversationId }));
}

export default function* chatSagas() {
  yield all([
    takeLeading(types.createConversation, commonApiSaga, { apiFn: api.chat.createConversation }),
    takeLeadingPerKey(types.getConversation, commonApiSaga, ({ payload }) => payload.conversationId, { apiFn: api.chat.getConversation, failureMessage: false }),
    takeLeadingPerKey(types.getConversations, commonApiSaga, ({ payload }) => payload?.conversationIds?.join('-') || 'all', { apiFn: api.chat.getConversations, failureMessage: true }),
    takeLeadingPerKey(types.updateConversation, commonApiSaga, ({ payload }) => payload.conversationId, { apiFn: api.chat.updateConversation }),

    takeLeading(types.getToken, commonApiSaga, { apiFn: api.chat.getToken }),
    takeLeading(types.getConversableMembers, commonApiSaga, { apiFn: api.chat.getConversableMembers }),

    takeLeadingPerKey(types.getMembersForConversation, commonApiSaga, ({ payload }) => payload.conversationId, { apiFn: api.chat.getMembersForConversation }),
    takeLeadingPerKey(types.conversationMemberDelete, onConversationMemberDelete, ({ payload }) => `${payload.conversationId}-${payload.memberId}`),
    takeLeadingPerKey(types.conversationMemberAdd, onConversationMemberAdd, ({ payload }) => `${payload.conversationId}`),
  ]);
}
