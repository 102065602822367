import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

const Wrapper = styled('div')`
  width: 100%;
  display: flex;
  align-items: space-between;
  font-weight: 500;

  * {
    width: 100%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  *:first-of-type {
    text-align: left;
  }

  *:last-of-type {
    text-align: right;
  }
`;

const RatingLabelRow = ({ left, mid, right }) => (
  <Wrapper>
    <div>{left}</div>
    {mid && <div>{mid}</div>}
    <div>{right}</div>
  </Wrapper>
);

RatingLabelRow.propTypes = {
  left: PropTypes.string,
  mid: PropTypes.string,
  right: PropTypes.string,
};

export default RatingLabelRow;
