import PropTypes from 'prop-types';
import React from 'react';
import fieldRenderProps from 'components/FinalForm/fieldRenderProps';
import LikertField from 'components/FinalForm/Fields/LikertField';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import PulseEmojiField from './PulseEmojiField';

const PulseScaleField = ({ pulseScale, pulseScaleRangeType, pulseScaleLabelGroup, ...rest }) => {
  const { t } = useTranslation('pulse');

  const renderLikert = () => {
    let labels = {};

    if (pulseScaleLabelGroup) {
      labels = pulseScale.possibleLabelGroups.find(labelGroup => labelGroup.value === pulseScaleLabelGroup)?.labels;
    }

    let likertOptions = {};

    if (pulseScaleRangeType === 'labels_in_extremes') {
      likertOptions = {
        [pulseScale.rangeStart]: labels[pulseScale.rangeStart],
        [pulseScale.rangeEnd]: labels[pulseScale.rangeEnd],
      };
    } else {
      likertOptions = [...Array(pulseScale.rangeEnd + 1).keys()].reduce((acc, key) => {
        const step = key + pulseScale.rangeStart;
        return { ...acc, [step]: labels[step] };
      }, {});
    }

    if (pulseScaleRangeType !== 'numbers' && !pulseScaleLabelGroup) {
      return null;
    }

    return (
      <LikertField
        start={pulseScale.rangeStart}
        max={pulseScale.rangeEnd}
        optionLabel={pulseScaleRangeType === 'numbers' ? 'value' : 'label'}
        options={likertOptions}
        {...rest}
      />
    );
  };

  if (['faces', 'thumbs'].includes(pulseScale?.type)) {
    return <Field {...rest} emojiType={pulseScale?.type} component={PulseEmojiField} style={{ margin: '1.5rem 0' }} />;
  }

  if (pulseScale?.type === 'enps') {
    const enpsOptions = [...Array(11).keys()].reduce((acc, key) => ({ ...acc, [key]: key }), {});

    return (
      <LikertField
        start={0}
        max={11}
        optionLabel="label"
        options={enpsOptions}
        {...rest}
        bottomLeftLabel={t('scale.label.completely_unlikely')}
        bottomRightLabel={t('scale.label.completely_likely')}
      />
    );
  }

  if (pulseScale?.type === 'likert' && pulseScaleRangeType) {
    return renderLikert();
  }
};

PulseScaleField.propTypes = {
  ...fieldRenderProps,
  pulseScale: PropTypes.object.isRequired,
  pulseScaleRangeType: PropTypes.string,
  pulseScaleLabelGroup: PropTypes.string,
};

export default PulseScaleField;
