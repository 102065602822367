import IconButton from '@mui/material/IconButton';
import React from 'react';
import Badge from '@mui/material/Badge';
import { useUnreads } from '@talkjs/react';
import MessagingIcon from 'components/Icons/Messaging';
import useSideQueryParam from '../useSideQueryParam';

const ChatIconButton = (args) => {

  const [, setSide] = useSideQueryParam();

  const unreads = useUnreads();

  const onClick = () => {
    setSide('inbox');
  };

  return (
    <IconButton onClick={onClick} {...args}>
      <Badge badgeContent={unreads?.length} color="primary">
        <MessagingIcon />
      </Badge>
    </IconButton>
  );
};

export default ChatIconButton;
