import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import DrawerBody from 'containers/Side/Drawer/DrawerBody';
import { Inbox } from '@talkjs/react';
import useChat from 'hooks/useChat';
import Grid from 'components/Grid';
import Button from 'components/Button';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import Form from 'components/FinalForm/Form';
import DrawerContent from 'containers/Side/Drawer/DrawerContent';
import { useDispatch, useSelector } from 'react-redux';
import { chatActions, chatSelectors, chatTypes } from 'redux/chat';
import MemberAutocomplete from 'containers/Fields/MemberAutocomplete';
import { useTranslation } from 'react-i18next';
import useReduxLoading from 'hooks/useReduxLoading';
import useSideQueryParam from '../useSideQueryParam';
import SideContext from '../SideContext';


const SideChatInbox = () => {

  const dispatch = useDispatch();
  const [, setSide] = useSideQueryParam();
  const { setTitle } = useContext(SideContext);
  const { t } = useTranslation('chat');

  const scrollBody = useRef();

  const [mode, setMode] = useState();

  const { onShowConversation } = useChat();

  useEffect(() => {
    if (mode === 'create') {
      dispatch(chatActions.getConversableMembers());
    }
  }, [dispatch, mode]);

  const options = useSelector(chatSelectors.getConversableMemberIds);
  const optionsLoading = useReduxLoading(
    chatTypes.getConversableMembers,
    [chatTypes.getConversableMembersSuccess, chatTypes.getConversableMembersFailure],
    true,
  );

  const onSelectConversation = useCallback((event) => {
    if (!event.conversation) {
      return;
    }
    setSide();
    onShowConversation(event.conversation.id);

    event.preventDefault();
  }, [onShowConversation, setSide]);

  useEffect(() => {
    if (mode === 'create') {
      setTitle({
        children: (
          <Grid container direction="row" wrap="nowrap" spacing={1} alignItems="center" justifyContent="space-between">
            <Grid item>{t('chat:newChat.title')}</Grid>
          </Grid>
        ),
        backButton: { onClick: () => setMode('view') },
      });
    } else {
      setTitle({
        children: (
          <Grid container direction="row" wrap="nowrap" spacing={1} alignItems="center" justifyContent="space-between">
            <Grid item>{t('chat:side.title')}</Grid>
            <Grid item>
              <Button variant="text" size="small" startIcon={<CreateRoundedIcon />} edge="end" onClick={() => setMode('create')}>{t('chat:side.createNewChat')}</Button>
            </Grid>
          </Grid>
        ),
        backButton: { onClick: () => setSide() },
      });
    }
  }, [mode, setSide, setTitle, t]);

  const onCreateSuccess = ({ data: conversationId }) => {
    onShowConversation(conversationId);
    setSide();
  };

  if (mode === 'create') {
    return (
      <DrawerBody>
        <DrawerContent>
          <Form
            start={chatTypes.createConversation}
            resolve={chatTypes.createConversationSuccess}
            reject={chatTypes.createConversationFailure}
            onSuccess={onCreateSuccess}
            initialValues={{ memberIds: [] }}
          >
            {({ handleSubmit }) => (
              <Grid container spacing={2} direction="column" wrap="nowrap">
                <Grid item>
                  <MemberAutocomplete.FinalForm
                    multiple
                    name="memberIds"
                    label={t('chat:newChat.membersLabel')}
                    placeholder={t('chat:newChat.membersPlaceholder')}

                    options={options}
                    loading={optionsLoading}

                    disableCloseOnSelect
                  />
                </Grid>
                <Grid item>
                  <Grid container direction="row" wrap="nowrap" spacing={2} alignItems="center" justifyContent="flex-end">
                    <Grid item><Button variant="text" onClick={() => setMode('view')}>{t('chat:newChat.cancel')}</Button></Grid>
                    <Grid item><Button onClick={handleSubmit}>{t('chat:newChat.create')}</Button></Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Form>
        </DrawerContent>
      </DrawerBody>
    );
  }

  return (
    <DrawerBody ref={scrollBody}>
      <Inbox
        showChatHeader={false}
        showFeedHeader={false}
        showMobileBackButton={false}

        selected={null}

        theme="guavahr"

        // onConversationSelected={onConversationSelected}
        onSelectConversation={onSelectConversation}

        style={{ height: '100%', width: '100%' }}
      />
    </DrawerBody>
  );
};

export default SideChatInbox;
