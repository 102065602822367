import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import styled from '@emotion/styled/macro';
import fieldRenderProps from 'components/FinalForm/fieldRenderProps';

const Content = styled('div')`
  label + & {
    margin-top: ${({ theme }) => (theme.spacing(3))};
  }
`;

const CustomFieldBase = ({ label, margin, info, error, helperText, children }) => {
  return (
    <FormControl hiddenLabel={!label} margin={margin} variant="standard">
      {label && <InputLabel shrink>{label}</InputLabel>}
      <Content>{children}</Content>
      <FormHelperText hidden={!info}>{info}</FormHelperText>
      <FormHelperText hidden={!error} error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

CustomFieldBase.propTypes = {
  info: PropTypes.string,
  ...fieldRenderProps,
};

export default CustomFieldBase;
