import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import styled from '@emotion/styled/macro';
import { ReactComponent as EmojiThumbsUpColoredSvg } from './assets/emoji-thumbs-up-colored.svg';

const StyledSvgIcon = styled(SvgIcon)`
  color: #5083f1;
`;

const EmojiThumbsUpColoredIcon = args => <StyledSvgIcon component={EmojiThumbsUpColoredSvg} {...args} />;

export default EmojiThumbsUpColoredIcon;
