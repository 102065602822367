import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled/macro';
import Grid from 'components/Grid';
import Form from 'components/FinalForm/Form';
import FormField from 'components/FinalForm/Fields/FormField';
import Button from 'components/Button';
import Collapse from '@mui/material/Collapse';
import { useDispatch, useSelector } from 'react-redux';
import { pulseActions } from 'redux/pulse';
import { authSelectors } from 'redux/auth';
import validator, { isRequired } from 'validators';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useFeature from 'hooks/useFeature';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import IconText from 'components/IconText';
import useMediaQuery from '@mui/material/useMediaQuery';
import ActivePulseSurveyFormResponseRate from './ActivePulseSurveyFormResponseRate';
import PulseScaleField from './PulseScaleField';

const Wrapper = styled('div')`
  margin: 2.5rem 0;
`;

const SmallText = styled(IconText)`
  color: #666666;
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  white-space: initial;
  text-align: center;
`;

const ActivePulseSurveyFormResponseRateStyled = styled(ActivePulseSurveyFormResponseRate)`
  display: flex;
  justify-content: center;
  text-align: center;
`;

const BigText = styled('div')`
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
`;

const FormFieldWrapper = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const FormFieldWrapperInner = styled('div')`
  max-width: 500px;
  width: 100%;
`;

const StyledFormField = styled(FormField)`
  textarea:not(:focus) {
    &::-webkit-input-placeholder {
      opacity: 1;
    }
    &::-moz-placeholder {
      opacity: 1;
    }
    &:-ms-input-placeholder {
      opacity: 1;
    }
    &::-ms-input-placeholder {
      opacity: 1;
    }
  }
`;

const ActivePulseSurveyForm = ({ onFinish = () => {}, ...rest }) => {
  const dispatch = useDispatch();

  const thanksRef = useRef();

  const pulseFeature = useFeature('pulse');

  const { t } = useTranslation(['component']);

  const workspace = useSelector(authSelectors.getWorkspace);

  const { activePulseSurvey } = workspace;

  const [visible, setVisible] = useState(false);

  const smallScreen = useMediaQuery('(max-width: 400px)');

  // Display fast if required. Disappear slow
  // Disappearance is likely due to answering... So fade away
  useEffect(() => {
    if (activePulseSurvey) {
      setVisible(true);
      return;
    }

    if (!workspace) {
      return;
    }

    if (visible) {
      const fn = () => {
        setVisible(false);
        onFinish();
      };
      const timeout = setTimeout(fn, 2000);
      return () => clearTimeout(timeout);
    }

    onFinish();
  }, [onFinish, visible, workspace, activePulseSurvey]);

  if (!pulseFeature.enabled) {
    return null;
  }

  const onSubmit = values => {
    dispatch(pulseActions.storePulseSurveyResponse(values));
    if (thanksRef.current) {
      thanksRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  };

  const optionsRequiringComment = activePulseSurvey?.pulseScaleOptionsRequiringComment || [];
  const validate = validator((values) => ({
    selectedPulseScaleOption: [isRequired],
    comment: optionsRequiringComment.includes(values.selectedPulseScaleOption) ? [isRequired] : [],
  }));

  return (
    <Collapse in={visible} unmountOnExit {...rest}>
      <Form onSubmit={onSubmit} validate={validate}>
        {({ handleSubmit, values, submitting, submitSucceeded }) => (
          <Wrapper>
            <Collapse in={submitSucceeded} ref={thanksRef}>
              <BigText>{t('component:activePulseSurveyForm.thanksForResponse')}</BigText>
            </Collapse>
            <Collapse component="form" in={!submitSucceeded} onSubmit={handleSubmit}>
              <Grid container direction="column" wrap="nowrap" spacing={3}>
                <Grid item>
                  <SmallText>
                    {t('component:activePulseSurveyForm.introSubText', { workspace: workspace.name })}
                  </SmallText>
                  <BigText>{activePulseSurvey.question}</BigText>
                  <SmallText>{activePulseSurvey.body}</SmallText>
                  <ActivePulseSurveyFormResponseRateStyled
                    percentage={activePulseSurvey.responsesPercentage}
                    max={activePulseSurvey.targetsMembersCount}
                  />
                </Grid>

                <Grid item>
                  <PulseScaleField
                    pulseScale={activePulseSurvey.pulseScale}
                    pulseScaleRangeType={activePulseSurvey.pulseScaleRangeType}
                    pulseScaleLabelGroup={activePulseSurvey.pulseScaleLabelGroup}
                    name="selectedPulseScaleOption"
                  />
                </Grid>

                <Collapse in={!!values.selectedPulseScaleOption} component={Grid} item>
                  <FormFieldWrapper>
                    <FormFieldWrapperInner>
                      <Grid container spacing={1} alignItems="center" justifyContent="center">
                        <Grid item xs={smallScreen ? 12 : true}>
                          <StyledFormField
                            name="comment"
                            variant="outlined"
                            margin="none"
                            size="medium"
                            multiline
                            maxRows={7}
                            minRows={1}
                            placeholder={t('component:activePulseSurveyForm.feedbackPlaceholder')}
                          />
                        </Grid>
                        <Grid item>
                          <Button type="submit" size="large" disabled={submitting}>
                            {t('component:activePulseSurveyForm.submit')}
                          </Button>
                        </Grid>
                      </Grid>
                    </FormFieldWrapperInner>
                  </FormFieldWrapper>
                </Collapse>
                <Grid item>
                  <SmallText
                    startIcon={
                      activePulseSurvey.anonymityLevel === 'anonymous' ? (
                        <LockOutlinedIcon fontSize="inherit" color="inherit" />
                      ) : (
                        <LockOpenOutlinedIcon fontSize="inherit" color="inherit" />)
                    }
                  >
                    {activePulseSurvey.anonymityLevel === 'anonymous'
                      && t('component:activePulseSurveyForm.anonymousDisclaimer')}
                    {activePulseSurvey.anonymityLevel === 'department_and_location'
                      && t('component:activePulseSurveyForm.departmentAndLocationDisclaimer', {
                        value: `${workspace.memberDepartment}, ${workspace.memberLocation}`,
                      })}
                    {activePulseSurvey.anonymityLevel === 'identified'
                      && t('component:activePulseSurveyForm.identifiedDisclaimer')}
                  </SmallText>
                </Grid>
              </Grid>
            </Collapse>
          </Wrapper>
        )}
      </Form>
    </Collapse>
  );
};

ActivePulseSurveyForm.propTypes = {
  onFinish: PropTypes.func,
};

export default ActivePulseSurveyForm;
