export default {
  getPulseSurveys: 'pulse/GET_PULSE_SURVEYS',
  getPulseSurveysSuccess: 'pulse/GET_PULSE_SURVEYS_SUCCESS',
  getPulseSurveysFailure: 'pulse/GET_PULSE_SURVEYS_FAILURE',

  storePulseSurvey: 'pulse/STORE_PULSE_SURVEY',
  storePulseSurveySuccess: 'pulse/STORE_PULSE_SURVEY_SUCCESS',
  storePulseSurveyFailure: 'pulse/STORE_PULSE_SURVEY_FAILURE',

  getPulseSurvey: 'pulse/GET_PULSE_SURVEY',
  getPulseSurveySuccess: 'pulse/GET_PULSE_SURVEY_SUCCESS',
  getPulseSurveyFailure: 'pulse/GET_PULSE_SURVEY_FAILURE',

  updatePulseSurvey: 'pulse/UPDATE_PULSE_SURVEY',
  updatePulseSurveySuccess: 'pulse/UPDATE_PULSE_SURVEY_SUCCESS',
  updatePulseSurveyFailure: 'pulse/UPDATE_PULSE_SURVEY_FAILURE',

  patchPulseSurvey: 'pulse/PATCH_PULSE_SURVEY',
  patchPulseSurveySuccess: 'pulse/PATCH_PULSE_SURVEY_SUCCESS',
  patchPulseSurveyFailure: 'pulse/PATCH_PULSE_SURVEY_FAILURE',

  deletePulseSurvey: 'pulse/DELETE_PULSE_SURVEY',
  deletePulseSurveySuccess: 'pulse/DELETE_PULSE_SURVEY_SUCCESS',
  deletePulseSurveyFailure: 'pulse/DELETE_PULSE_SURVEY_FAILURE',

  getPulseSurveyOverviewAnalytics: 'pulse/GET_PULSE_SURVEY_OVERVIEW_ANALYTICS',
  getPulseSurveyOverviewAnalyticsSuccess: 'pulse/GET_PULSE_SURVEY_OVERVIEW_ANALYTICS_SUCCESS',
  getPulseSurveyOverviewAnalyticsFailure: 'pulse/GET_PULSE_SURVEY_OVERVIEW_ANALYTICS_FAILURE',

  getPulseScales: 'pulse/GET_PULSE_SCALES',
  getPulseScalesSuccess: 'pulse/GET_PULSE_SCALES_SUCCESS',
  getPulseScalesFailure: 'pulse/GET_PULSE_SCALES_FAILURE',

  getPulseSurveyResponses: 'pulse/GET_PULSE_SURVEY_RESPONSES',
  getPulseSurveyResponsesSuccess: 'pulse/GET_PULSE_SURVEY_RESPONSES_SUCCESS',
  getPulseSurveyResponsesFailure: 'pulse/GET_PULSE_SURVEY_RESPONSES_FAILURE',

  storePulseSurveyResponse: 'pulse/STORE_PULSE_SURVEY_RESPONSE',
  storePulseSurveyResponseSuccess: 'pulse/STORE_PULSE_SURVEY_RESPONSE_SUCCESS',
  storePulseSurveyResponseFailure: 'pulse/STORE_PULSE_SURVEY_RESPONSE_FAILURE',

  downloadPulseSurveyResponses: 'pulse/DOWNLOAD_PULSE_SURVEY_RESPONSES',
  downloadPulseSurveyResponsesSuccess: 'pulse/DOWNLOAD_PULSE_SURVEY_RESPONSES_SUCCESS',
  downloadPulseSurveyResponsesFailure: 'pulse/DOWNLOAD_PULSE_SURVEY_RESPONSES_FAILURE',

  getPulseSurveyPeriods: 'pulse/GET_PULSE_SURVEY_PERIODS',
  getPulseSurveyPeriodsSuccess: 'pulse/GET_PULSE_SURVEY_PERIODS_SUCCESS',
  getPulseSurveyPeriodsFailure: 'pulse/GET_PULSE_SURVEY_PERIODS_FAILURE',

  getPulseSurveySchedules: 'pulse/GET_PULSE_SURVEY_SCHEDULES',
  getPulseSurveySchedulesSuccess: 'pulse/GET_PULSE_SURVEY_SCHEDULES_SUCCESS',
  getPulseSurveySchedulesFailure: 'pulse/GET_PULSE_SURVEY_SCHEDULES_FAILURE',

  storePulseSurveySchedule: 'pulse/STORE_PULSE_SURVEY_SCHEDULE',
  storePulseSurveyScheduleSuccess: 'pulse/STORE_PULSE_SURVEY_SCHEDULE_SUCCESS',
  storePulseSurveyScheduleFailure: 'pulse/STORE_PULSE_SURVEY_SCHEDULE_FAILURE',
};
