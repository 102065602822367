import { all, debounce, put, select, takeLeading } from 'redux-saga/effects';
import { api, apiCall, commonApiSaga } from 'redux/helpers/api';
import history from 'providers/history';
import { authSelectors } from 'redux/auth';
import { snackbarActions } from 'redux/snackbar';
import types from './types';
import actions from './actions';

function* onStorePulseSurveyResponse({ payload, config }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, error } = yield apiCall(api.pulse.storePulseSurveyResponse, { ...payload, workspaceId }, config);

  if (ok) {
    yield put(actions.storePulseSurveyResponseSuccess(payload));
    yield put(snackbarActions.createSuccess('workspace.feedbackSent'));
  } else {
    yield put(actions.sendPulseFeedbackFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

function* onDownloadPulseSurveyResponses({ payload }) {
  const workspaceId = yield select(authSelectors.getWorkspaceId);

  const { ok, error, response } = yield apiCall(api.pulse.downloadPulseSurveyResponses, { ...payload, workspaceId });

  if (ok) {
    yield put(actions.downloadPulseSurveyResponsesSuccess({ data: response }));
  } else {
    yield put(actions.downloadPulseSurveyResponsesFailure(error));
    yield put(snackbarActions.createFailure(error.message));
  }
}

export default function* pulseSagas() {
  yield all([
    takeLeading(types.getPulseSurveys, commonApiSaga, { apiFn: api.pulse.getPulseSurveys, workspaceId: true }),
    takeLeading(types.storePulseSurvey, commonApiSaga, {
      apiFn: api.pulse.storePulseSurvey,
      workspaceId: true,
      successMessage: 'pulse.pulseSurveyCreated',
    }),
    takeLeading(types.getPulseSurvey, commonApiSaga, {
      apiFn: api.pulse.showPulseSurvey,
      workspaceId: true,
      onFailure: () => history.push('/'),
    }),
    takeLeading(types.updatePulseSurvey, commonApiSaga, {
      apiFn: api.pulse.updatePulseSurvey,
      workspaceId: true,
      successMessage: 'pulse.pulseSurveyUpdated',
    }),
    takeLeading(types.patchPulseSurvey, commonApiSaga, {
      apiFn: api.pulse.patchPulseSurvey,
      workspaceId: true,
      successMessage: 'pulse.pulseSurveyUpdated',
    }),
    takeLeading(types.deletePulseSurvey, commonApiSaga, {
      apiFn: api.pulse.deletePulseSurvey,
      workspaceId: true,
      successMessage: 'pulse.pulseSurveyDeleted',
    }),
    takeLeading(types.getPulseSurveyOverviewAnalytics, commonApiSaga, {
      apiFn: api.pulse.showPulseSurveyOverviewAnalytics,
      workspaceId: true,
      onFailure: () => history.push('/'),
    }),
    takeLeading(types.getPulseScales, commonApiSaga, { apiFn: api.pulse.getPulseScales, workspaceId: true }),
    takeLeading(types.getPulseSurveyResponses, commonApiSaga, { apiFn: api.pulse.getPulseSurveyResponses, workspaceId: true }),
    takeLeading(types.storePulseSurveyResponse, onStorePulseSurveyResponse),
    debounce(200, types.downloadPulseSurveyResponses, onDownloadPulseSurveyResponses),
  ]);
}
