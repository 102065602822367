import { createAction } from 'redux/helpers/actions';
import types from './types';

export default {
  getPulseSurveys: data => createAction(types.getPulseSurveys, data),
  getPulseSurveysSuccess: () => createAction(types.getPulseSurveysSuccess),
  getPulseSurveysFailure: () => createAction(types.getPulseSurveysFailure),

  getPulseSurvey: data => createAction(types.getPulseSurvey, data),
  getPulseSurveySuccess: () => createAction(types.getPulseSurveySuccess),
  getPulseSurveyFailure: () => createAction(types.getPulseSurveyFailure),

  updatePulseSurvey: data => createAction(types.updatePulseSurvey, data),
  updatePulseSurveySuccess: () => createAction(types.updatePulseSurveySuccess),
  updatePulseSurveyFailure: () => createAction(types.updatePulseSurveyFailure),

  patchPulseSurvey: data => createAction(types.patchPulseSurvey, data),
  patchPulseSurveySuccess: () => createAction(types.patchPulseSurveySuccess),
  patchPulseSurveyFailure: () => createAction(types.patchPulseSurveyFailure),

  deletePulseSurvey: data => createAction(types.deletePulseSurvey, data),
  deletePulseSurveySuccess: () => createAction(types.deletePulseSurveySuccess),
  deletePulseSurveyFailure: () => createAction(types.deletePulseSurveyFailure),

  getPulseSurveyOverviewAnalytics: data => createAction(types.getPulseSurveyOverviewAnalytics, data),
  getPulseSurveyOverviewAnalyticsSuccess: () => createAction(types.getPulseSurveyOverviewAnalyticsSuccess),
  getPulseSurveyOverviewAnalyticsFailure: () => createAction(types.getPulseSurveyOverviewAnalyticsFailure),

  getPulseScales: data => createAction(types.getPulseScales, data),
  getPulseScalesSuccess: data => createAction(types.getPulseScalesSuccess, data),
  getPulseScalesFailure: data => createAction(types.getPulseScalesFailure, data),

  getPulseSurveyResponses: data => createAction(types.getPulseSurveyResponses, data),
  getPulseSurveyResponsesSuccess: (data) => createAction(types.getPulseSurveyResponsesSuccess, data),
  getPulseSurveyResponsesFailure: (data) => createAction(types.getPulseSurveyResponsesFailure, data),

  storePulseSurveyResponse: data => createAction(types.storePulseSurveyResponse, data),
  storePulseSurveyResponseSuccess: () => createAction(types.storePulseSurveyResponseSuccess),
  updatePulseSurveyResponseFailure: () => createAction(types.storePulseSurveyResponseFailure),

  downloadPulseSurveyResponses: data => createAction(types.downloadPulseSurveyResponses, data),
  downloadPulseSurveyResponsesSuccess: (data) => createAction(types.downloadPulseSurveyResponsesSuccess, data),
  downloadPulseSurveyResponsesFailure: (data) => createAction(types.downloadPulseSurveyResponsesFailure, data),

  getPulseSurveyPeriods: data => createAction(types.getPulseSurveyPeriods, data),
  getPulseSurveyPeriodsSuccess: data => createAction(types.getPulseSurveyPeriodsSuccess, data),
  getPulseSurveyPeriodsFailure: data => createAction(types.getPulseSurveyPeriodsFailure, data),

  getPulseSurveySchedules: data => createAction(types.getPulseSurveySchedules, data),
  getPulseSurveySchedulesSuccess: data => createAction(types.getPulseSurveySchedulesSuccess, data),
  getPulseSurveySchedulesFailure: data => createAction(types.getPulseSurveySchedulesFailure, data),

  storePulseSurveySchedule: data => createAction(types.storePulseSurveySchedule, data),
  storePulseSurveyScheduleSuccess: () => createAction(types.storePulseSurveyScheduleSuccess),
  updatePulseSurveyScheduleFailure: () => createAction(types.storePulseSurveyScheduleFailure),
};
