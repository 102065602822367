import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from 'components/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { chatActions, chatSelectors, chatTypes } from 'redux/chat';
import { workspaceSelectors } from 'redux/workspace';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import DialogActions from 'components/Dialog/DialogActions';
import styled from '@emotion/styled/macro';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Form from 'components/FinalForm/Form';
import TrashIcon from 'components/Icons/Trash';
import IconButton from '@mui/material/IconButton';
import MemberAutocomplete from 'containers/Fields/MemberAutocomplete';
import PaperSeparator from 'components/Paper/PaperSeparator';
import PropTypes from 'prop-types';

const Name = styled('div')`
  font-weight: 500;
`;

const Job = styled('span')`
  color: #666;
  font-size: 0.9em;
`;

const MemberListItem = ({ conversationId, member, canDelete, ...rest }) => {

  const dispatch = useDispatch();

  const onDelete = () => {
    dispatch(chatActions.conversationMemberDelete({ conversationId, memberId: member.id }));
  };

  if (!member) {
    return null;
  }

  return (
    <ListItem disableGutters {...rest}>
      <Grid container direction="row" spacing={1} wrap="nowrap" alignItems="center">
        <Grid item>
          <Avatar size={36} src={member.avatar?.src} srcSet={member.avatar?.srcSet} firstName={member.firstName} lastName={member.lastName} />
        </Grid>
        <Grid item xs zeroMinWidth>
          <ListItemText
            primary={<Name>{member.name}</Name>}
            secondary={<Job>{member.job}</Job>}
          />
        </Grid>
        <Grid item>
          {canDelete && (
            <IconButton size="small" onClick={onDelete}>
              <TrashIcon fontSize="small" />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </ListItem>
  );
};


MemberListItem.propTypes = {
  conversationId: PropTypes.any.isRequired,
  member: PropTypes.shape({
    id: PropTypes.any,
    avatar: PropTypes.any,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    name: PropTypes.string,
    job: PropTypes.string,
  }),
  canDelete: PropTypes.bool,
};

const AddMembersBlock = ({ conversationId, ...rest }) => {

  const { t } = useTranslation('chat');

  const dispatch = useDispatch();

  const onAddSuccess = () => {
    dispatch(chatActions.getMembersForConversation({ conversationId }));
  };

  return (
    <Form
      start={chatTypes.conversationMemberAdd}
      resolve={chatTypes.conversationMemberAddSuccess}
      reject={chatTypes.conversationMemberAddFailure}
      onSuccess={onAddSuccess}
      initialValues={{ memberIds: [], conversationId }}

      {...rest}
    >
      {({ handleSubmit }) => (
        <Grid container spacing={2} direction="column" wrap="nowrap">
          <Grid item>
            <MemberAutocomplete.FinalForm
              multiple
              name="memberIds"
              label={t('chat:manageMembers.add.membersLabel')}
              placeholder={t('chat:manageMembers.add.membersPlaceholder')}

              disableCloseOnSelect

              helperText="NB: All added member's will see the whole conversation history!"
            />
          </Grid>
          <Grid item>
            <Grid container direction="row" wrap="nowrap" spacing={2} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <Button onClick={handleSubmit}>{t('chat:manageMembers.add.addButton')}</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Form>
  );
};

AddMembersBlock.propTypes = {
  conversationId: PropTypes.any.isRequired,
};

const ManageMembersDialog = ({ conversationId, onClose, ...rest }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    if (!conversationId) {
      return;
    }
    dispatch(chatActions.getMembersForConversation({ conversationId }));
  }, [conversationId, dispatch]);

  const conversation = useSelector(chatSelectors.getConversationSelector)(conversationId);

  const memberIds = useSelector(chatSelectors.getMemberIdsForConversation)(conversationId);

  const getMemberSelector = useSelector(workspaceSelectors.getMemberSelector);

  // TODO: Translations etc.
  if (conversation?.permissions?.manageMembers === false) {
    return (
      <Dialog maxWidth="xs" fullWidth onClose={onClose} {...rest}>
        <DialogTitle align="center">
          Members
        </DialogTitle>
        <DialogContent>
          Not allowed
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog maxWidth="xs" fullWidth onClose={onClose} {...rest}>
      <DialogTitle align="center">
        Members
      </DialogTitle>
      <DialogContent>
        {conversation?.permissions?.manageMembers && (
          <AddMembersBlock conversationId={conversationId} />
        )}
        <PaperSeparator />
        <List>
          {memberIds
            .map(getMemberSelector)
            .filter(v => !!v)
            .map((member, idx, arr) => (
              <MemberListItem
                key={member.id}
                member={member}
                divider={arr?.length - 1 !== idx}

                conversationId={conversationId}
                canDelete={conversation?.permissions?.manageMembers}
              />
            ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" wrap="nowrap" alignItems="center" justifyContent="flex-end">
          <Grid item>
            <Button variant="text" onClick={onClose}>Done</Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

ManageMembersDialog.propTypes = {
  conversationId: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ManageMembersDialog;
