export default {
  getWorkspaceSettings: 'workspace/GET_WORKSPACE_SETTINGS',
  getWorkspaceSettingsSuccess: 'workspace/GET_WORKSPACE_SETTINGS_SUCCESS',
  getWorkspaceSettingsFailure: 'workspace/GET_WORKSPACE_SETTINGS_FAILURE',

  updateWorkspaceSettings: 'workspace/UPDATE_WORKSPACE_SETTINGS',
  updateWorkspaceSettingsSuccess: 'workspace/UPDATE_WORKSPACE_SETTINGS_SUCCESS',
  updateWorkspaceSettingsFailure: 'workspace/UPDATE_WORKSPACE_SETTINGS_FAILURE',

  getWorkspaceProfile: 'workspace/GET_WORKSPACE_PROFILE',
  getWorkspaceProfileSuccess: 'workspace/GET_WORKSPACE_PROFILE_SUCCESS',
  getWorkspaceProfileFailure: 'workspace/GET_WORKSPACE_PROFILE_FAILURE',

  updateWorkspaceProfile: 'workspace/UPDATE_WORKSPACE_PROFILE',
  updateWorkspaceProfileSuccess: 'workspace/UPDATE_WORKSPACE_PROFILE_SUCCESS',
  updateWorkspaceProfileFailure: 'workspace/UPDATE_WORKSPACE_PROFILE_FAILURE',

  getWorkspacePulseSettings: 'workspace/GET_WORKSPACE_PULSE_SETTINGS',
  getWorkspacePulseSettingsSuccess: 'workspace/GET_WORKSPACE_PULSE_SETTINGS_SUCCES',
  getWorkspacePulseSettingsFailure: 'workspace/GET_WORKSPACE_PULSE_SETTINGS_FAILURE',

  updateWorkspacePulseSettings: 'workspace/UPDATE_WORKRSPACE_PULSE_SETTINGS',
  updateWorkspacePulseSettingsSuccess: 'workspace/UPDATE_WORKRSPACE_PULSE_SETTINGS_SUCCESS',
  updateWorkspacePulseSettingsFailure: 'workspace/UPDATE_WORKRSPACE_PULSE_SETTINGS_FAILURE',

  getWorkspaceChannels: 'workspace/GET_WORKSPACE_CHANNELS',
  getWorkspaceChannelsSuccess: 'workspace/GET_WORKSPACE_CHANNELS_SUCCESS',
  getWorkspaceChannelsFailure: 'workspace/GET_WORKSPACE_CHANNELS_FAILURE',

  getGroups: 'workspace/GET_GROUPS',
  getGroupsSuccess: 'workspace/GET_GROUPS_SUCCESS',
  getGroupsFailure: 'workspace/GET_GROUPS_FAILURE',

  getMembers: 'workspace/GET_MEMBERS',
  getMembersSuccess: 'workspace/GET_MEMBERS_SUCCESS',
  getMembersFailure: 'workspace/GET_MEMBERS_FAILURE',

  exportMembers: 'workspace/EXPORT_MEMBERS',
  exportMembersSuccess: 'workspace/EXPORT_MEMBERS_SUCCESS',
  exportMembersFailure: 'workspace/EXPORT_MEMBERS_FAILURE',

  getMember: 'workspace/GET_MEMBER',
  getMemberSuccess: 'workspace/GET_MEMBER_SUCCESS',
  getMemberFailure: 'workspace/GET_MEMBER_FAILURE',

  addMember: 'workspace/ADD_MEMBER',
  addMemberSuccess: 'workspace/ADD_MEMBER_SUCCESS',
  addMemberFailure: 'workspace/ADD_MEMBER_FAILURE',

  updateMember: 'workspace/UPDATE_MEMBER',
  updateMemberSuccess: 'workspace/UPDATE_MEMBER_SUCCESS',
  updateMemberFailure: 'workspace/UPDATE_MEMBER_FAILURE',

  deleteMember: 'workspace/DELETE_MEMBER',
  deleteMemberSuccess: 'workspace/DELETE_MEMBER_SUCCESS',
  deleteMemberFailure: 'workspace/DELETE_MEMBER_FAILURE',

  reinviteMember: 'workspace/REINVITE_MEMBER',
  reinviteMemberSuccess: 'workspace/REINVITE_MEMBER_SUCCESS',
  reinviteMemberFailure: 'workspace/REINVITE_MEMBER_FAILURE',

  memberMassAction: 'workspace/MEMBER_MASS_ACTION',
  memberMassActionSuccess: 'workspace/MEMBER_MASS_ACTION_SUCCESS',
  memberMassActionFailure: 'workspace/MEMBER_MASS_ACTION_FAILURE',

  getUser: 'workspace/GET_USER',
  getUserSuccess: 'workspace/GET_USER_SUCCESS',
  getUserFailure: 'workspace/GET_USER_FAILURE',

  updateUser: 'workspace/UPDATE_USER',
  updateUserSuccess: 'workspace/UPDATE_USER_SUCCESS',
  updateUserFailure: 'workspace/UPDATE_USER_FAILURE',

  getLabel: 'workspace/GET_LABEL',
  getLabelSuccess: 'workspace/GET_LABEL_SUCCESS',
  getLabelFailure: 'workspace/GET_LABEL_FAILURE',

  getLabels: 'workspace/GET_LABELS',
  getLabelsSuccess: 'workspace/GET_LABELS_SUCCESS',
  getLabelsFailure: 'workspace/GET_LABELS_FAILURE',

  getLabelMembers: 'workspace/GET_LABEL_MEMBERS',
  getLabelMembersSuccess: 'workspace/GET_LABEL_MEMBERS_SUCCESS',
  getLabelMembersFailure: 'workspace/GET_LABEL_MEMBERS_FAILURE',

  getInvitation: 'workspace/GET_INVITATION',
  getInvitationSuccess: 'workspace/GET_INVITATION_SUCCESS',
  getInvitationFailure: 'workspace/GET_INVITATION_FAILURE',

  regenerateInvitation: 'workspace/REGENERATE_INVITATION',
  regenerateInvitationSuccess: 'workspace/REGENERATE_INVITATION_SUCCESS',
  regenerateInvitationFailure: 'workspace/REGENERATE_INVITATION_FAILURE',

  getChallenges: 'workspace/GET_CHALLENGES',
  getChallengesSuccess: 'workspace/GET_CHALLENGES_SUCCESS',
  getChallengesFailure: 'workspace/GET_CHALLENGES_FAILURE',

  getChallengeTemplates: 'workspace/GET_CHALLENGE_TEMPLATES',
  getChallengeTemplatesSuccess: 'workspace/GET_CHALLENGE_TEMPLATES_SUCCESS',
  getChallengeTemplatesFailure: 'workspace/GET_CHALLENGE_TEMPLATES_FAILURE',

  storeChallenge: 'workspace/STORE_CHALLENGE',
  storeChallengeSuccess: 'workspace/STORE_CHALLENGE_SUCCESS',
  storeChallengeFailure: 'workspace/STORE_CHALLENGE_FAILURE',

  updateChallenge: 'workspace/UPDATE_CHALLENGE',
  updateChallengeSuccess: 'workspace/UPDATE_CHALLENGE_SUCCESS',
  updateChallengeFailure: 'workspace/UPDATE_CHALLENGE_FAILURE',

  exportChallenge: 'workspace/EXPORT_CHALLENGE',
  exportChallengeSuccess: 'workspace/EXPORT_CHALLENGE_SUCCESS',
  exportChallengeFailure: 'workspace/EXPORT_CHALLENGE_FAILURE',

  endChallenge: 'workspace/END_CHALLENGE',
  endChallengeSuccess: 'workspace/END_CHALLENGE_SUCCESS',
  endChallengeFailure: 'workspace/END_CHALLENGE_FAILURE',

  getAnalyticsOverview: 'workspace/GET_ANALYTICS_OVERVIEW',
  getAnalyticsOverviewSuccess: 'workspace/GET_ANALYTICS_OVERVIEW_SUCCESS',
  getAnalyticsOverviewFailure: 'workspace/GET_ANALYTICS_OVERVIEW_FAILURE',

  getAnalyticsPosts: 'workspace/GET_ANALYTICS_POSTS',
  getAnalyticsPostsSuccess: 'workspace/GET_ANALYTICS_POSTS_SUCCESS',
  getAnalyticsPostsFailure: 'workspace/GET_ANALYTICS_POSTS_FAILURE',

  getAnalyticsComments: 'workspace/GET_ANALYTICS_COMMENTS',
  getAnalyticsCommentsSuccess: 'workspace/GET_ANALYTICS_COMMENTS_SUCCESS',
  getAnalyticsCommentsFailure: 'workspace/GET_ANALYTICS_COMMENTS_FAILURE',

  getAnalyticsLikes: 'workspace/GET_ANALYTICS_LIKES',
  getAnalyticsLikesSuccess: 'workspace/GET_ANALYTICS_LIKES_SUCCESS',
  getAnalyticsLikesFailure: 'workspace/GET_ANALYTICS_LIKES_FAILURE',

  getAnalyticsMembers: 'workspace/GET_ANALYTICS_MEMBERS',
  getAnalyticsMembersSuccess: 'workspace/GET_ANALYTICS_MEMBERS_SUCCESS',
  getAnalyticsMembersFailure: 'workspace/GET_ANALYTICS_MEMBERS_FAILURE',

  getAnalyticsUsers: 'workspace/GET_ANALYTICS_USERS',
  getAnalyticsUsersSuccess: 'workspace/GET_ANALYTICS_USERS_SUCCESS',
  getAnalyticsUsersFailure: 'workspace/GET_ANALYTICS_USERS_FAILURE',

  getAnalyticsActiveness: 'workspace/GET_ANALYTICS_ACTIVENESS',
  getAnalyticsActivenessSuccess: 'workspace/GET_ANALYTICS_ACTIVENESS_SUCCESS',
  getAnalyticsActivenessFailure: 'workspace/GET_ANALYTICS_ACTIVENESS_FAILURE',

  getAnalyticsAdoption: 'workspace/GET_ANALYTICS_ADOPTION',
  getAnalyticsAdoptionSuccess: 'workspace/GET_ANALYTICS_ADOPTION_SUCCESS',
  getAnalyticsAdoptionFailure: 'workspace/GET_ANALYTICS_ADOPTION_FAILURE',

  getAnalyticsEngagement: 'workspace/GET_ANALYTICS_ENGAGEMENT',
  getAnalyticsEngagementSuccess: 'workspace/GET_ANALYTICS_ENGAGEMENT_SUCCESS',
  getAnalyticsEngagementFailure: 'workspace/GET_ANALYTICS_ENGAGEMENT_FAILURE',

  sendPulseFeedback: 'workspace/SEND_PULSE_FEEDBACK',
  sendPulseFeedbackSuccess: 'workspace/SEND_PULSE_FEEDBACK_SUCCESS',
  sendPulseFeedbackFailure: 'workspace/SEND_PULSE_FEEDBACK_FAILURE',

  getAnalyticsPulse: 'workspace/GET_ANALYTICS_PULSE',
  getAnalyticsPulseSuccess: 'workspace/GET_ANALYTICS_PULSE_SUCCESS',
  getAnalyticsPulseFailure: 'workspace/GET_ANALYTICS_PULSE_FAILURE',

  getAnalyticsPulseExport: 'workspace/GET_ANALYTICS_PULSE_EXPORT',
  getAnalyticsPulseExportSuccess: 'workspace/GET_ANALYTICS_PULSE_EXPORT_SUCCESS',
  getAnalyticsPulseExportFailure: 'workspace/GET_ANALYTICS_PULSE_EXPORT_FAILURE',

  getAnalyticsFunnel: 'workspace/GET_ANALYTICS_FUNNEL',
  getAnalyticsFunnelSuccess: 'workspace/GET_ANALYTICS_FUNNEL_SUCCESS',
  getAnalyticsFunnelFailure: 'workspace/GET_ANALYTICS_FUNNEL_FAILURE',

  workspaceSearch: 'workspace/WORKSPACE_SEARCH',
  workspaceSearchSuccess: 'workspace/WORKSPACE_SEARCH_SUCCESS',
  workspaceSearchFailure: 'workspace/WORKSPACE_SEARCH_FAILURE',

  workspaceSearchAutocomplete: 'workspace/WORKSPACE_SEARCH_AUTOCOMPLETE',
  workspaceSearchAutocompleteSuccess: 'workspace/WORKSPACE_SEARCH_AUTOCOMPLETE_SUCCESS',
  workspaceSearchAutocompleteFailure: 'workspace/WORKSPACE_SEARCH_AUTOCOMPLETE_FAILURE',

  getMemberRoles: 'workspace/GET_MEMBER_ROLES',
  getMemberRolesSuccess: 'workspace/GET_MEMBER_ROLES_SUCCESS',
  getMemberRolesFailure: 'workspace/GET_MEMBER_ROLES_FAILURE',

  getContactDirectory: 'workspace/GET_CONTACT_DIRECTORY',
  getContactDirectorySuccess: 'workspace/GET_CONTACT_DIRECTORY_SUCCESS',
  getContactDirectoryFailure: 'workspace/GET_CONTACT_DIRECTORY_FAILURE',

  getDepartments: 'workspace/GET_DEPARTMENTS',
  getDepartmentsSuccess: 'workspace/GET_DEPARTMENTS_SUCCESS',
  getDepartmentsFailure: 'workspace/GET_DEPARTMENTS_FAILURE',

  getLocations: 'workspace/GET_LOCATIONS',
  getLocationsSuccess: 'workspace/GET_LOCATIONS_SUCCESS',
  getLocationsFailure: 'workspace/GET_LOCATIONS_FAILURE',
};
