import React, { useRef, useState } from 'react';
import styled from '@emotion/styled/macro';
import MaxWidthContainer from 'components/MaxWidthContainer';
import NavLink from 'components/NavLink';
import Grid from 'components/Grid';
import AuthenticatedUserMenu from 'containers/AuthenticatedUserMenu';
import NotificationMenu from 'containers/Notification/NotificationMenu';
import CreatePostDialog from 'containers/Post/PostForm/CreatePostDialog';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import useFeature from 'hooks/useFeature';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';

import LayoutLogo from './LayoutLogo';
import ContactDirectoryIconButton from './Side/ContactDirectory/ContactDirectoryIconButton';
import SidebarDrawer from './Sidebar/SidebarDrawer';
import ChatIconButton from './Side/Chat/ChatIconButton';

const Wrapper = styled('div')`
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  min-height: 52px;

  padding: 0.5rem 0;

  background: #fff;
  color: #666;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.04),
              0px 0px 8px rgba(0, 0, 0, 0.08);

  z-index: ${({ theme }) => theme.zIndex.navbar};

  @media(max-width: 500px) {
    .auth-menu-name {
      display: none;
    }
  }
`;

const LogoLink = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 36px;
  max-width: 200px;
  min-width: 75px;
`;

const WebNavbar = ({ withSidebar = false }) => {
  const { t } = useTranslation(['component']);

  const wrapper = useRef();

  const [postOpen, setPostOpen] = useState(false);

  const peopleDirectory = useFeature('peopleDirectory');
  const postFeature = useFeature('post');
  const chatFeature = useFeature('chat');

  const smallScreen = useMediaQuery('(max-width: 520px)');

  return (
    <Wrapper ref={wrapper} className="mui-fixed">
      <MaxWidthContainer>
        <Grid container spacing={2} alignItems="center" wrap="nowrap">
          <Grid item>
            {withSidebar
              ? <SidebarDrawer compact={smallScreen} edge="start" />
              : <LogoLink to="/"><LayoutLogo /></LogoLink>}
          </Grid>

          <Grid item xs container alignItems="center" justifyContent="flex-end">
            {postFeature.enabled && <Grid item><Button color="primary" startIcon={<AddCircleIcon />} onClick={() => setPostOpen(true)} nowrap>{t('component:navbar.createPost')}</Button></Grid>}
          </Grid>

          <Grid item>
            <Grid container spacing={0.5} alignItems="center" wrap="nowrap">
              {/* TODO: Find a better icon */}
              {chatFeature.enabled && <Grid item><ChatIconButton color="inherit" fontSize="small" size="small" /></Grid>}

              {peopleDirectory.enabled && <Grid item><ContactDirectoryIconButton color="inherit" fontSize="small" size="small" /></Grid>}

              <Grid item><NotificationMenu /></Grid>
              <Grid item><AuthenticatedUserMenu compact={smallScreen} /></Grid>
            </Grid>
          </Grid>

        </Grid>
      </MaxWidthContainer>
      <CreatePostDialog open={postOpen} onClose={() => setPostOpen(false)} />
    </Wrapper>
  );
};

WebNavbar.propTypes = {
  withSidebar: PropTypes.bool,
};


export default WebNavbar;
