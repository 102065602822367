import { schema } from 'normalizr';
import labelSchema from './label';
import pulseScaleSchema from './pulseScale';

const pulseSurveySchema = new schema.Entity('pulseSurveys', {
  targets: [labelSchema],
  pulseScale: pulseScaleSchema,
});

export default pulseSurveySchema;
