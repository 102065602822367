import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import { InView } from 'react-intersection-observer';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import fieldRenderProps from 'components/FinalForm/fieldRenderProps';
import pulseService from 'services/pulseService';
import getErrorProperties from 'components/FinalForm/getErrorProperties';
import FormHelperText from '@mui/material/FormHelperText';
import PropTypes from 'prop-types';

const Wrapper = styled('div')``;

const Emoji = styled('div')`
  margin: 0.25rem;
  font-size: 5rem;
  padding: 1rem;

  @media (max-width: 800px) {
    font-size: 4rem;
    margin: 0;
    padding: 0.75rem;
  }

  @media (max-width: 400px) {
    padding: 0.5rem;
  }

  svg {
    font-size: 1em;
    transition: opacity 300ms, filter 200ms, color 300ms;
  }

  &.active,
  &:focus {
    background: rgba(0, 0, 0, 0.04);
  }

  &.not-active svg {
    color: #ffe17d;
    opacity: 0.5;
    filter: grayscale(1);
  }
`;

const ErrorHelperText = styled(FormHelperText)`
  position: relative;
  bottom: auto;
`;

const Emojis = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PulseEmojiField = ({ input: { value: currentValue, onChange }, meta, emojiType = 'faces', ...rest }) => {
  const [inView, setInView] = useState(false);

  const generateEmoji = (Icon, value) => {
    const onClick = () => onChange(value === currentValue ? null : value);

    const className = [
      currentValue === value ? 'active' : '',
      currentValue && currentValue !== value ? 'not-active' : '',
    ].join(' ');

    return (
      <Emoji as={IconButton} disableRipple onClick={onClick} className={className}>
        <Icon />
      </Emoji>
    );
  };

  const inViewArgs = {
    threshold: 0.75,
    onChange: bool => setInView(current => current || bool),
    triggerOnce: true,
  };

  const choices = pulseService.getActiveChoices(emojiType);

  const { error, helperText } = getErrorProperties(meta);

  return (
    <Wrapper>
      <InView as={Emojis} {...inViewArgs} {...rest}>
        {choices.map((choice, idx) => (
          <Grow in={inView} key={choice.value} timeout={idx * 300}>
            {generateEmoji(choice.Emoji, choice.value)}
          </Grow>
        ))}
      </InView>
      {error && <ErrorHelperText error>{helperText}</ErrorHelperText>}
    </Wrapper>
  );
};

PulseEmojiField.propTypes = {
  ...fieldRenderProps,
  emojiType: PropTypes.oneOf(['faces', 'thumbs']),
};

export default PulseEmojiField;
