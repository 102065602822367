import boostSchema from 'schemas/boost';
import postSchema, { getFeedPostSchema } from 'schemas/post';
import channelSchema from 'schemas/channel';
import commentSchema from 'schemas/comment';
import feedbackCommentSchema from 'schemas/feedbackComment';
import likeSchema from 'schemas/like';
import activityCategorySchema from 'schemas/activityCategory';
import groupSchema from 'schemas/group';
import challengeSchema from 'schemas/challenge';
import memberSchema from 'schemas/member';
import eventSchema from 'schemas/event';
import feedbackSchema from 'schemas/feedback';
import feedbackTypeSchema from 'schemas/feedbackType';
import recordSchema from 'schemas/record';
import roleSchema from 'schemas/role';
import searchSchema from 'schemas/search';
import translationSchema from 'schemas/translation';
import articleSchema from 'schemas/article';
import contactDirectorySchema from 'schemas/contactDirectory';
import fileGroupSchema from 'schemas/fileGroup';
import notificationSchema from 'schemas/notification';
import pollSchema from 'schemas/poll';
import quizSchema from 'schemas/quiz';
import courseSchema from 'schemas/course';
import learningCourseSchema from 'schemas/learningCourse';
import learningLessonSchema from 'schemas/learningLesson';
import learningQuestionSchema from 'schemas/learningQuestion';
import documentFolderSchema from 'schemas/documentFolder';
import documentSectionSchema from 'schemas/documentSection';
import labelSchema from 'schemas/label';
import pageSchema from 'schemas/page';
import learningCourseVariantSchema from 'schemas/learningCourseVariant';
import coopIntegrationSchema from 'schemas/coopIntegration';
import integrationRuleSchema from 'schemas/integrationRule';
import integrationSchema from 'schemas/integration';
import { snackbarActions } from 'redux/snackbar';
import { call, put, select } from 'redux-saga/effects';
import { authSelectors } from 'redux/auth';
import contactSchema from 'schemas/contact';
import pulseSurveySchema from 'schemas/pulseSurvey';
import pulseScaleSchema from 'schemas/pulseScale';
import pulseSurveyOverviewAnalytics from 'schemas/pulseSurveyOverviewAnalytics';
import pulseSurveyResponseSchema from 'schemas/pulseSurveyResponse';
import conversationSchema from 'schemas/conversation';
import request from './request';

const apiGenerator = (requestFn) => ({

  auth: {
    // Authentication flow
    login: data => requestFn('/auth/login', 'POST', data, { auth: false }),
    logout: data => requestFn('/auth/logout', 'POST', data, { auth: false }),

    // Password reset flow
    forgot: data => requestFn('/auth/password/forgot', 'POST', data, { auth: false }),
    reset: data => requestFn('/auth/password/reset', 'POST', data, { auth: false }),

    // Registration flow
    verifyToken: data => requestFn('/invitation/verify', 'POST', data, { auth: false }),
    findInvitation: data => requestFn('/invitation/find', 'POST', data, { auth: false }),
    registerSend: data => requestFn('/auth/register/send', 'POST', data, { auth: false }),
    registerValidate: data => requestFn('/auth/register/validate', 'POST', data, { auth: false }),
    register: data => requestFn('/auth/register', 'POST', data, { auth: false }),
    join: data => requestFn('/auth/join', 'POST', data, { auth: false }),

    // Other
    getZendeskToken: data => requestFn('/auth/zendesk-token', 'POST', data),
  },

  openai: {
    chatCompletions: (data, fetchOptions = {}) => requestFn('/api/openai/chat/completions', 'POST', data, { auth: true, fetchOptions }),
  },

  opengraph: {
    fetch: ({ anonymous, ...data }) => requestFn('/api/opengraph/fetch', 'GET', data, { auth: false, anonymous: anonymous ?? true }),
  },

  verification: {
    send: data => requestFn('/verification/send', 'POST', data, { auth: false }),
    validate: data => requestFn('/verification/validate', 'POST', data, { auth: false }),
  },

  files: {
    getFiles: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/files`, 'GET', data, { schema: [fileGroupSchema] }),
    saveFiles: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/files`, 'PUT', data, { schema: [fileGroupSchema] }),
  },

  guidance: {
    getArticles: data => requestFn('/guidance/article', 'GET', data, { schema: [articleSchema] }),
    getArticleCategories: () => requestFn('/guidance/article/category', 'GET', null),
    getArticle: ({ articleId }) => requestFn(`/guidance/article/${articleId}`, 'GET', null, { schema: articleSchema }),
    addLike: ({ articleId }) => requestFn(`/guidance/article/${articleId}/like`, 'POST'),
    removeLike: ({ articleId }) => requestFn(`/guidance/article/${articleId}/like`, 'DELETE'),
  },

  birthday: {
    getOverview: ({ workspaceId }) => requestFn(`/workspace/${workspaceId}/birthdays-overview`, 'GET'),
    getBirthdays: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/birthdays`, 'GET', { limit: 50, ...data }),
    getBirthdayEvent: ({ memberId, year }) => requestFn(`/member/${memberId}/birthday/${year}`, 'GET'),

    addComment: ({ eventId, ...data }) => requestFn(`/birthday-event/${eventId}/comment`, 'POST', data, { schema: commentSchema, formData: true }),
    getComments: ({ eventId, ...data }) => requestFn(`/birthday-event/${eventId}/comment`, 'GET', data, { schema: [commentSchema] }),
  },

  boost: {
    get: () => requestFn('/boost', 'GET', null, { schema: [boostSchema] }),
    update: ({ boostId, ...data }) => requestFn(`/boost/${boostId}/complete`, 'PUT', data),
  },

  calendar: {
    downloadIcs: ({ eventId, ...data }) => requestFn(`/event/${eventId}/download-ics`, 'GET', data, { blob: true }),
    getEvent: ({ eventId, ...data }) => requestFn(`/event/${eventId}`, 'GET', data, { schema: eventSchema }),
    getEventFeed: ({ eventId, ...data }) => requestFn(`/event/${eventId}/feed`, 'GET', data, { schema: [getFeedPostSchema('event', eventId)] }),
    getEvents: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/event`, 'GET', data, { schema: [eventSchema] }),
    createEvent: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/event`, 'POST', data, { schema: eventSchema, formData: true }),
    updateEvent: ({ eventId, ...data }) => requestFn(`/event/${eventId}`, 'POST', { ...data, _method: 'PUT' }, { schema: eventSchema, formData: true }),
    deleteEvent: ({ eventId, ...data }) => requestFn(`/event/${eventId}`, 'DELETE', data),
  },

  training: {
    getCourse: ({ courseId, ...data }) => requestFn(`/course/${courseId}`, 'GET', data, { schema: courseSchema }),
    getCourseFeed: ({ courseId, ...data }) => requestFn(`/course/${courseId}/feed`, 'GET', data, { schema: [getFeedPostSchema('course', courseId)] }),
    getCourses: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/course`, 'GET', data, { schema: [courseSchema] }),
    createCourse: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/course`, 'POST', data, { schema: courseSchema, formData: true }),
    updateCourse: ({ courseId, ...data }) => requestFn(`/course/${courseId}`, 'POST', { ...data, _method: 'PUT' }, { schema: courseSchema, formData: true }),
    deleteCourse: ({ courseId, ...data }) => requestFn(`/course/${courseId}`, 'DELETE', data),
  },

  drive: {
    getRecord: ({ recordId, ...data }) => requestFn(`/record/${recordId}`, 'GET', data, { schema: recordSchema }),
    getRecordFeed: ({ recordId, ...data }) => requestFn(`/record/${recordId}/feed`, 'GET', data, { schema: [getFeedPostSchema('record', recordId)] }),
    getRecords: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/record`, 'GET', data, { schema: [recordSchema] }),

    createRecord: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/record`, 'POST', data, { schema: recordSchema, formData: true }),
    updateRecord: ({ recordId, ...data }) => requestFn(`/record/${recordId}`, 'POST', { ...data, _method: 'PUT' }, { schema: recordSchema, formData: true }),
    deleteRecord: ({ recordId, ...data }) => requestFn(`/record/${recordId}`, 'DELETE', data),

    getRecordTypes: () => requestFn('/record-type', 'GET'),
  },

  challenge: {
    getChallenge: ({ challengeId }) => requestFn(`/challenge/${challengeId}`, 'GET', null, { schema: challengeSchema }),
    getChallengeFeed: ({ challengeId, ...data }) => requestFn(`/challenge/${challengeId}/feed`, 'GET', data, { schema: [getFeedPostSchema('challenge', challengeId)] }),
    getChallenges: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/challenge`, 'GET', data, { schema: [challengeSchema] }),
    getChallengesFeed: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/challenge/feed`, 'GET', data, { schema: [getFeedPostSchema('challenges')] }),
    getActivityCategories: ({ workspaceId }) => requestFn(`/workspace/${workspaceId}/activity/category`, 'GET', null, { schema: [activityCategorySchema] }),
    storeActivity: data => requestFn('/activity', 'POST', data, { formData: true }),
    updateActivity: ({ postId, ...data }) => requestFn(`/activity/${postId}`, 'POST', { ...data, _method: 'PUT' }, { formData: true }),
  },

  channel: {
    get: ({ channelId, ...data }) => requestFn(`/channel/${channelId}`, 'GET', data, { schema: channelSchema }),
    getFeed: ({ channelId, ...data }) => requestFn(`/channel/${channelId}/feed`, 'GET', data, { schema: [getFeedPostSchema('channel', channelId)] }),
    subscribe: ({ channelId, ...data }) => requestFn(`/channel/${channelId}/subscribe`, 'POST', data),
  },

  chat: {
    getToken: (data) => requestFn('/chat/token', 'POST', data),
    getConversableMembers: ({ ...data }) => requestFn('/chat/conversable-members', 'GET', data, { schema: [memberSchema] }), // TODO: Better api url

    getConversation: ({ conversationId, ...data }) => requestFn(`/conversation/${conversationId}`, 'GET', data, { schema: conversationSchema }),
    getConversations: ({ conversationIds, ...data }) => requestFn('/conversation', 'GET', { conversationIds, ...data }, { schema: [conversationSchema] }),
    updateConversation: ({ conversationId, ...data }) => requestFn(`/conversation/${conversationId}`, 'POST', { ...data, _method: 'PUT' }, { schema: conversationSchema, formData: true }), // TODO: Better api url
    createConversation: (data) => requestFn('/conversation', 'POST', data, { schema: conversationSchema }),

    getMembersForConversation: ({ conversationId, ...data }) => requestFn(`/conversation/${conversationId}/member`, 'GET', data, { schema: [memberSchema] }),
    conversationMemberDelete: ({ conversationId, memberId, ...data }) => requestFn(`/conversation/${conversationId}/member/${memberId}`, 'DELETE', data),
    conversationMemberAdd: ({ conversationId, ...data }) => requestFn(`/conversation/${conversationId}/member`, 'POST', data),
  },

  comment: {
    get: ({ commentId }) => requestFn(`/comment/${commentId}`, 'GET'),
    delete: ({ commentId }) => requestFn(`/comment/${commentId}`, 'DELETE'),
    update: ({ commentId, ...data }) => requestFn(`/comment/${commentId}`, 'PUT', data),
    getLikes: ({ commentId }) => requestFn(`/comment/${commentId}/like`, 'GET', {}, { schema: [likeSchema] }),
    removeLike: ({ commentId }) => requestFn(`/comment/${commentId}/like`, 'DELETE'),
    addLike: ({ commentId, ...data }) => requestFn(`/comment/${commentId}/like`, 'POST', data),
    getTranslation: ({ commentId, ...data }) => requestFn(`/comment/${commentId}/translations`, 'GET', data, { schema: [translationSchema] }),
  },

  jobAnniversary: {
    getOverview: ({ workspaceId }) => requestFn(`/workspace/${workspaceId}/job-anniversaries-overview`, 'GET'),
    getJobAnniversaries: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/job-anniversaries`, 'GET', { limit: 50, ...data }),
    getJobAnniversaryEvent: ({ memberId, year }) => requestFn(`/member/${memberId}/job-anniversary/${year}`, 'GET'),

    addComment: ({ eventId, ...data }) => requestFn(`/job-anniversary-event/${eventId}/comment`, 'POST', data, { schema: commentSchema, formData: true }),
    getComments: ({ eventId, ...data }) => requestFn(`/job-anniversary-event/${eventId}/comment`, 'GET', data, { schema: [commentSchema] }),
  },

  member: {
    getMemberFeed: ({ memberId, ...data }) => requestFn(`/member/${memberId}/feed`, 'GET', data, { schema: [getFeedPostSchema('member', memberId)] }),
  },

  poll: {
    getPoll: ({ pollId, ...data }) => requestFn(`/poll/${pollId}`, 'GET', data, { schema: pollSchema }),
    addOption: ({ pollId, ...data }) => requestFn(`/poll/${pollId}/option`, 'POST', data),
    removeOption: ({ pollId, optionId }) => requestFn(`/poll/${pollId}/option/${optionId}`, 'DELETE'),

    downloadVotes: ({ pollId, ...data }) => requestFn(`/poll/${pollId}/download`, 'GET', data, { blob: true }),

    getVotes: ({ optionId }) => requestFn(`/option/${optionId}/vote`, 'GET', null, { schema: [memberSchema] }),
    storeVote: ({ optionId }) => requestFn(`/option/${optionId}/vote`, 'POST'),
    deleteVote: ({ optionId }) => requestFn(`/option/${optionId}/vote`, 'DELETE'),
  },

  quiz: {
    getQuiz: ({ quizId, ...data }) => requestFn(`/quiz/${quizId}`, 'GET', data, { schema: quizSchema }),
    downloadAnswers: ({ quizId, ...data }) => requestFn(`/quiz/${quizId}/download`, 'GET', data, { blob: true }),
    storeAnswer: ({ quizId, optionId }) => requestFn(`/quiz/${quizId}/option/${optionId}/answer`, 'POST', null, { schema: quizSchema }),
  },

  post: {
    get: ({ postId }) => requestFn(`/post/${postId}`, 'GET', {}, { schema: postSchema }),
    store: data => requestFn('/post', 'POST', data, { formData: true, schema: postSchema }),
    update: ({ postId, ...data }) => requestFn(`/post/${postId}`, 'POST', { ...data, _method: 'PUT' }, { formData: true, schema: postSchema }),
    patch: ({ postId, ...data }) => requestFn(`/post/${postId}`, 'PATCH', data, { schema: postSchema }),
    delete: ({ postId }) => requestFn(`/post/${postId}`, 'DELETE'),

    getReachPercentages: ({ postIds, ...data }) => requestFn('/post/reach-percentages', 'GET', { postIds, ...data }),

    addComment: ({ postId, ...data }) => requestFn(`/post/${postId}/comment`, 'POST', data, { schema: commentSchema, formData: true }),
    getComments: ({ postId, ...data }) => requestFn(`/post/${postId}/comment`, 'GET', data, { schema: [commentSchema] }),

    getLikes: ({ postId }) => requestFn(`/post/${postId}/like`, 'GET', {}, { schema: [likeSchema] }),
    removeLike: ({ postId }) => requestFn(`/post/${postId}/like`, 'DELETE'),
    addLike: ({ postId, ...data }) => requestFn(`/post/${postId}/like`, 'POST', data),

    getSmsExpenseEstimation: data => requestFn('/post/sms-expense-estimation', 'POST', data),

    getTranslations: ({ postId, ...data }) => requestFn(`/post/${postId}/translations`, 'GET', data, { schema: [translationSchema] }),

    follow: ({ postId }) => requestFn(`/post/${postId}/follow`, 'POST', { postId, follow: true }, { schema: postSchema }),
    unfollow: ({ postId }) => requestFn(`/post/${postId}/follow`, 'POST', { postId, follow: false }, { schema: postSchema }),
  },

  group: {
    get: ({ id }) => requestFn(`/group/${id}`, 'GET', {}, { schema: groupSchema }),
    getPosts: ({ groupId, ...data }) => requestFn(`/group/${groupId}/post`, 'GET', data, { schema: [getFeedPostSchema('group', groupId)] }),
    subscribe: ({ id, subscribe }) => requestFn(`/group/${id}/subscribe`, 'POST', { subscribe }),
    store: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/group`, 'POST', data, { schema: groupSchema, formData: true }),
    update: ({ groupId, ...data }) => requestFn(`/group/${groupId}`, 'POST', { ...data, _method: 'PUT' }, { formData: true, schema: groupSchema }),
    delete: ({ groupId }) => requestFn(`/group/${groupId}`, 'DELETE'),
    getMembers: ({ groupId, ...data }) => requestFn(`/group/${groupId}/member`, 'GET', { limit: 999, ...data }, { schema: [memberSchema] }),
  },

  contract: {

    get: data => requestFn('/contract', 'GET', data, { auth: false }),
    download: ({ contractId, ...data }) => requestFn(`/contract/${contractId}/download`, 'GET', data, { blob: true }),

    workspace: {
      getContracts: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/contract`, 'GET', data),
      downloadContract: ({ workspaceId, contractId, ...data }) => requestFn(`/workspace/${workspaceId}/contract/${contractId}/download`, 'GET', data, { blob: true }),
      acceptContract: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/contract`, 'POST', data),
    },
  },

  integration: {
    index: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/integration`, 'GET', data, { schema: [integrationSchema] }),
    patch: ({ workspaceId, integrationId, ...data }) => requestFn(`/workspace/${workspaceId}/integration/${integrationId}`, 'PATCH', data, { schema: integrationSchema }),

    rules: {
      index: ({ workspaceId, integrationId, ...data }) => requestFn(`/workspace/${workspaceId}/integration/${integrationId}/rule`, 'GET', data, { schema: [integrationRuleSchema] }),
      create: ({ workspaceId, integrationId, ...data }) => requestFn(`/workspace/${workspaceId}/integration/${integrationId}/rule`, 'POST', data, { schema: integrationRuleSchema }),
      update: ({ integrationRuleId, integrationId, workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/integration/${integrationId}/rule/${integrationRuleId}`, 'PUT', data, { schema: integrationRuleSchema }),

      massaction: ({ integrationId, workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/integration/${integrationId}/rule/massaction`, 'POST', data, { schema: [integrationRuleSchema] }),
      autocomplete: ({ integrationId, workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/integration/${integrationId}/rule/autocomplete`, 'GET', data),
    },
    persona: {
      get: ({ workspaceId }) => requestFn(`/workspace/${workspaceId}/integration/persona/settings`, 'GET'),
      update: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/integration/persona/settings`, 'PUT', data),
    },
    netvisor: {
      get: ({ workspaceId }) => requestFn(`/workspace/${workspaceId}/integration/netvisor/settings`, 'GET'),
      update: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/integration/netvisor/settings`, 'PUT', data),
      sync: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/integration/netvisor/sync`, 'POST', data),
    },
    coop: {
      get: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/integration/coop/settings`, 'GET', data, { schema: coopIntegrationSchema }),
      update: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/integration/coop/settings`, 'PUT', data),
      sync: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/integration/coop/sync`, 'POST', data),
    },
  },

  feedback: {
    store: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/feedback`, 'POST', data, { formData: true, schema: feedbackSchema }),
    publicStore: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/feedback`, 'POST', data, { formData: true, anonymous: true, auth: false, schema: undefined }),
    patch: ({ feedbackId, ...data }) => requestFn(`/feedback/${feedbackId}`, 'POST', { ...data, _method: 'PATCH' }, { formData: true, schema: feedbackSchema }),
    massAction: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/feedback/massaction`, 'POST', data),
    index: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/feedback`, 'GET', data, { schema: [feedbackSchema] }),
    show: ({ feedbackId, ...data }) => requestFn(`/feedback/${feedbackId}`, 'GET', data, { schema: feedbackSchema, anonymous: !!data.token, auth: !data.token }),
    delete: ({ feedbackId, ...data }) => requestFn(`/feedback/${feedbackId}`, 'DELETE', data),

    download: ({ feedbackId, ...data }) => requestFn(`/feedback/${feedbackId}/download`, 'GET', data, { blob: true }),
    downloadIndex: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/feedback/download`, 'GET', data, { blob: true }),
  },

  feedbackComments: {
    index: ({ feedbackId, ...data }) => requestFn(`/feedback/${feedbackId}/comment`, 'GET', data, { schema: [feedbackCommentSchema], anonymous: !!data.token, auth: !data.token }),
    store: ({ feedbackId, ...data }) => requestFn(`/feedback/${feedbackId}/comment`, 'POST', data, { schema: feedbackCommentSchema, formData: true, anonymous: !!data.token, auth: !data.token }),
    show: ({ feedbackId, feedbackCommentId, ...data }) => requestFn(`/feedback/${feedbackId}/comment/${feedbackCommentId}`, 'GET', data),
    update: ({ feedbackId, feedbackCommentId, ...data }) => requestFn(`/feedback/${feedbackId}/comment/${feedbackCommentId}`, 'PUT', data, { schema: feedbackCommentSchema }),
    delete: ({ feedbackId, feedbackCommentId, ...data }) => requestFn(`/feedback/${feedbackId}/comment/${feedbackCommentId}`, 'DELETE', data),
    translations: ({ feedbackId, feedbackCommentId, ...data }) => requestFn(`/feedback/${feedbackId}/comment/${feedbackCommentId}/translations`, 'GET', data, { schema: [translationSchema], anonymous: !!data.token, auth: !data.token }),
  },

  feedbackStatus: {
    index: (data) => requestFn('/feedback-status', 'GET', data),
  },

  feedbackSettings: {
    get: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/feedback-settings`, 'GET', data),
    update: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/feedback-settings`, 'PUT', data),
  },

  feedbackType: {
    index: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/feedback-type`, 'GET', data, { schema: [feedbackTypeSchema] }),
    store: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/feedback-type`, 'POST', data, { schema: feedbackTypeSchema }),
    show: ({ feedbackTypeId, ...data }) => requestFn(`/feedback-type/${feedbackTypeId}`, 'GET', data, { schema: feedbackTypeSchema, auth: !data.token, anonymous: !!data.token }),
    update: ({ feedbackTypeId, ...data }) => requestFn(`/feedback-type/${feedbackTypeId}`, 'PUT', data, { schema: feedbackTypeSchema }),
    patch: ({ feedbackTypeId, ...data }) => requestFn(`/feedback-type/${feedbackTypeId}`, 'PATCH', data, { schema: feedbackTypeSchema }),
    delete: ({ feedbackTypeId, ...data }) => requestFn(`/feedback-type/${feedbackTypeId}`, 'DELETE', data),
    getPublicUrl: ({ feedbackTypeId, ...data }) => requestFn(`/feedback-type/${feedbackTypeId}/public-url`, 'GET', data),
  },

  workspace: {
    get: ({ workspaceId }) => requestFn(`/workspace/${workspaceId}`, 'GET'),
    channels: ({ workspaceId }) => requestFn(`/workspace/${workspaceId}/channel`, 'GET', null, { schema: [channelSchema] }),
    homeFeed: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/feed`, 'GET', data, { schema: [getFeedPostSchema('home')] }),
    groups: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/group`, 'GET', data, { schema: [groupSchema] }),
    groupsFeed: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/group/feed`, 'GET', data, { schema: [getFeedPostSchema('groups')] }),

    label: ({ workspaceId, labelId, ...data }) => requestFn(`/workspace/${workspaceId}/labels/${labelId}`, 'GET', data, { schema: labelSchema }),
    labels: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/labels`, 'GET', data, { schema: [labelSchema] }),
    labelMembers: ({ workspaceId, labelId, ...data }) => requestFn(`/workspace/${workspaceId}/labels/${labelId}/members`, 'GET', data, { schema: [memberSchema] }),

    invitation: ({ workspaceId }) => requestFn(`/workspace/${workspaceId}/invitation`, 'GET'),
    regenerateInvitation: ({ workspaceId }) => requestFn(`/workspace/${workspaceId}/invitation/regenerate`, 'POST'),

    settings: ({ workspaceId }) => requestFn(`/workspace/${workspaceId}/settings`, 'GET'),
    updateSettings: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/settings`, 'POST', { ...data, _method: 'PUT' }, { formData: true }),

    profile: ({ workspaceId }) => requestFn(`/workspace/${workspaceId}/profile`, 'GET'),
    updateProfile: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/profile`, 'POST', { ...data, _method: 'PUT' }, { formData: true }),

    pulseSettings: ({ workspaceId }) => requestFn(`/workspace/${workspaceId}/settings/pulse`, 'GET'),
    updatePulseSettings: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/settings/pulse`, 'PUT', data),

    sendPulseFeedback: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/pulse`, 'POST', data),
    getUser: ({ workspaceId }) => requestFn(`/workspace/${workspaceId}/user`, 'GET'),
    updateUser: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/user`, 'POST', { ...data, _method: 'PUT' }),

    getContactDirectory: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/people-directory`, 'GET', data, { schema: [contactDirectorySchema] }),

    search: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/search`, 'GET', data, { schema: [searchSchema] }),

    contact: {
      store: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/contact`, 'POST', data, { schema: contactSchema }),
      show: ({ workspaceId, contactId, ...data }) => requestFn(`/workspace/${workspaceId}/contact/${contactId}`, 'GET', data, { schema: contactSchema }),
      update: ({ workspaceId, contactId, ...data }) => requestFn(`/workspace/${workspaceId}/contact/${contactId}`, 'PUT', data, { schema: contactSchema }),
      delete: ({ workspaceId, contactId, ...data }) => requestFn(`/workspace/${workspaceId}/contact/${contactId}`, 'DELETE', data),
    },

    wysiwyg: {
      storeImage: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/wysiwyg/image`, 'POST', data, { formData: true }),
      storeFile: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/wysiwyg/file`, 'POST', data, { formData: true }),
    },

    roles: {
      index: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/roles`, 'GET', data, { schema: [roleSchema] }),
    },

    challenge: {
      get: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/challenge`, 'GET', data, { schema: [challengeSchema] }),
      templates: ({ workspaceId }) => requestFn(`/workspace/${workspaceId}/challenge/template`, 'GET'),
      store: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/challenge`, 'POST', data),
      update: ({ challengeId, ...data }) => requestFn(`/challenge/${challengeId}`, 'POST', { _method: 'PUT', ...data }),
      download: ({ challengeId, ...data }) => requestFn(`/challenge/${challengeId}/export`, 'GET', data, { blob: true }),
    },

    analytics: {
      getOverview: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/analytics/overview`, 'GET', data),
      getPulse: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/analytics/pulse`, 'GET', data),
      getPulseExport: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/analytics/pulse/export`, 'GET', data, { blob: true }),
      getFunnel: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/analytics/funnel`, 'GET', data),
      getActiveness: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/analytics/activeness`, 'GET', data),
      getAdoption: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/analytics/adoption`, 'GET', data),
      getEngagement: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/analytics/engagement`, 'GET', data),
      getPosts: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/analytics/posts`, 'GET', data),
      getLikes: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/analytics/likes`, 'GET', data),
      getMembers: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/analytics/members`, 'GET', data),
      getUsers: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/analytics/users`, 'GET', data),
      getComments: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/analytics/comments`, 'GET', data),
    },

    member: {
      index: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/member`, 'GET', data, { schema: [memberSchema] }),
      show: ({ workspaceId, memberId, ...data }) => requestFn(`/workspace/${workspaceId}/member/${memberId}`, 'GET', data, { schema: memberSchema }),
      export: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/member`, 'GET', data, { schema: [memberSchema], blob: true, headers: { Accept: 'text/csv' } }),
      store: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/member`, 'POST', data, { schema: memberSchema }),
      delete: ({ workspaceId, memberId }) => requestFn(`/workspace/${workspaceId}/member/${memberId}`, 'DELETE'),
      update: ({ workspaceId, memberId, ...data }) => requestFn(`/workspace/${workspaceId}/member/${memberId}`, 'PUT', data, { schema: memberSchema }),
      reinvite: ({ workspaceId, memberId }) => requestFn(`/workspace/${workspaceId}/member/${memberId}/invite`, 'POST'),
      massAction: ({ workspaceId, members, action, ...data }) => requestFn(`/workspace/${workspaceId}/member/massaction`, 'POST', { action, members, ...data }),
    },

    getDepartments: ({ workspaceId, ...data }) => request(`/workspace/${workspaceId}/departments`, 'GET', data),
    getLocations: ({ workspaceId, ...data }) => request(`/workspace/${workspaceId}/locations`, 'GET', data),
  },

  pages: {
    index: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/page`, 'GET', data, { schema: [pageSchema] }),
    store: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/page`, 'POST', data, { schema: pageSchema, formData: true }),
    patch: ({ workspaceId, pageId, ...data }) => requestFn(`/workspace/${workspaceId}/page/${pageId}`, 'PATCH', data, { schema: pageSchema }),
    show: ({ workspaceId, pageId, ...data }) => requestFn(`/workspace/${workspaceId}/page/${pageId}`, 'GET', data, { schema: pageSchema }),
    update: ({ workspaceId, pageId, ...data }) => requestFn(`/workspace/${workspaceId}/page/${pageId}`, 'POST', { ...data, _method: 'PUT' }, { schema: pageSchema, formData: true }),
    delete: ({ workspaceId, pageId, ...data }) => requestFn(`/workspace/${workspaceId}/page/${pageId}`, 'DELETE', data),
  },

  pulse: {
    getPulseSurveys: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/pulse-survey`, 'GET', data, { schema: [pulseSurveySchema] }),
    storePulseSurvey: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/pulse-survey`, 'POST', data, { schema: pulseSurveySchema, formData: true }),
    showPulseSurvey: ({ workspaceId, pulseSurveyId, ...data }) => requestFn(`/workspace/${workspaceId}/pulse-survey/${pulseSurveyId}`, 'GET', data, { schema: pulseSurveySchema }),
    updatePulseSurvey: ({ workspaceId, pulseSurveyId, ...data }) => requestFn(`/workspace/${workspaceId}/pulse-survey/${pulseSurveyId}`, 'POST', { ...data, _method: 'PUT' }, { schema: pulseSurveySchema, formData: true }),
    patchPulseSurvey: ({ workspaceId, pulseSurveyId, ...data }) => requestFn(`/workspace/${workspaceId}/pulse-survey/${pulseSurveyId}`, 'PATCH', data, { schema: pulseSurveySchema }),
    deletePulseSurvey: ({ workspaceId, pulseSurveyId, ...data }) => requestFn(`/workspace/${workspaceId}/pulse-survey/${pulseSurveyId}`, 'DELETE', data),
    showPulseSurveyOverviewAnalytics: ({ workspaceId, pulseSurveyId, ...data }) => requestFn(`/workspace/${workspaceId}/pulse-survey/${pulseSurveyId}/overview-analytics`, 'GET', data, { schema: pulseSurveyOverviewAnalytics }),
    getPulseScales: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/pulse-scale`, 'GET', data, { schema: [pulseScaleSchema] }),
    getPulseSurveyResponses: ({ workspaceId, pulseSurveyId, ...data }) => requestFn(`/workspace/${workspaceId}/pulse-survey/${pulseSurveyId}/response`, 'GET', data, { schema: [pulseSurveyResponseSchema] }),
    storePulseSurveyResponse: ({ workspaceId, ...data }) => requestFn(`/workspace/${workspaceId}/pulse-survey-response`, 'POST', data),
    downloadPulseSurveyResponses: ({ workspaceId, pulseSurveyId, ...data }) => requestFn(`/workspace/${workspaceId}/pulse-survey/${pulseSurveyId}/response/download`, 'GET', data, { blob: true }),
  },

  user: {
    sendAnalytics: data => requestFn('/user/action', 'POST', data),

    getProfile: () => requestFn('/user/profile', 'GET'),
    getSettings: () => requestFn('/user/settings', 'GET'),
    updateProfile: data => requestFn('/user/profile', 'POST', { ...data, _method: 'PUT' }),
    updateSettings: data => requestFn('/user/settings', 'POST', { ...data, _method: 'PUT' }),
    getNotificationSettings: () => requestFn('/user/notification/settings', 'GET'),
    updateNotificationSettings: data => requestFn('/user/notification/settings', 'PUT', data),
    updateAvatar: data => requestFn('/user/profile/avatar', 'POST', { ...data, _method: 'PUT' }, { formData: true }),
    deleteAvatar: () => requestFn('/user/profile/avatar', 'DELETE'),
    getNotifications: data => requestFn('/user/notification', 'GET', data, { schema: [notificationSchema] }),
    readNotifications: data => requestFn('/user/notification/read', 'POST', data),

    sendEmailChangeVerification: data => requestFn('/user/email/', 'POST', data),
  },

  learning: {
    trainingPlan: {
      byCourses: ({ workspaceId, ...data }) => requestFn(`/learning/workspace/${workspaceId}/training-plan/courses`, 'GET', data),
      byLabels: ({ workspaceId, ...data }) => requestFn(`/learning/workspace/${workspaceId}/training-plan/labels`, 'GET', data),
    },

    getCourses: data => requestFn('/learning/course/', 'GET', data, { schema: [learningCourseSchema] }),
    getCourse: ({ courseId, ...data }) => requestFn(`/learning/course/${courseId}`, 'GET', data, { schema: learningCourseSchema }),
    getLessons: ({ courseVariantId, ...data }) => requestFn(`/learning/course-variant/${courseVariantId}/lesson`, 'GET', data, { schema: [learningLessonSchema] }),
    getLesson: ({ lessonId, ...data }) => requestFn(`/learning/lesson/${lessonId}`, 'GET', data, { schema: learningLessonSchema }),
    getQuestions: ({ lessonId, ...data }) => requestFn(`/learning/lesson/${lessonId}/question`, 'GET', data, { schema: [learningQuestionSchema] }),
    getQuestion: ({ questionId, ...data }) => requestFn(`/learning/question/${questionId}`, 'GET', data, { schema: learningQuestionSchema }),
    postQuestionAnswer: ({ questionId, ...data }) => requestFn(`/learning/question/${questionId}/answer`, 'POST', data),

    getCoursesForAdmin: data => requestFn('/admin/learning/course/', 'GET', data, { schema: [learningCourseSchema] }),
    getCourseForAdmin: ({ courseId, ...data }) => requestFn(`/admin/learning/course/${courseId}`, 'GET', data, { schema: learningCourseSchema }),
    postCourseForAdmin: data => requestFn('/admin/learning/course/', 'POST', data),
    putCourseForAdmin: ({ courseId, ...data }) => requestFn(`/admin/learning/course/${courseId}`, 'PUT', data),
    deleteCourseVariantForAdmin: ({ courseVariantId, ...data }) => requestFn(`/admin/learning/course-variant/${courseVariantId}`, 'DELETE', data),
    duplicateCourseVariantForAdmin: ({ courseVariantId, ...data }) => requestFn(`/admin/learning/course-variant/${courseVariantId}/duplicate`, 'POST', data, { schema: learningCourseVariantSchema }),
    archiveCourseVariantForAdmin: ({ courseVariantId, ...data }) => requestFn(`/admin/learning/course-variant/${courseVariantId}/archive`, 'POST', { ...data, _method: 'PUT' }, { schema: learningCourseVariantSchema }),
    getLessonsForAdmin: ({ courseVariantId, ...data }) => requestFn(`/admin/learning/course-variant/${courseVariantId}/lesson`, 'GET', data, { schema: [learningLessonSchema] }),
    getLessonForAdmin: ({ lessonId, ...data }) => requestFn(`/admin/learning/lesson/${lessonId}`, 'GET', data, { schema: learningLessonSchema }),
    postLessonImageForAdmin: ({ courseVariantId, formData }) => requestFn(`/admin/learning/course-variant/${courseVariantId}/lesson/image`, 'POST', formData, { formData: true }),
    postLessonForAdmin: ({ courseVariantId, ...data }) => requestFn(`/admin/learning/course-variant/${courseVariantId}/lesson`, 'POST', data, { formData: true, schema: learningLessonSchema }),
    putLessonForAdmin: ({ lessonId, ...data }) => requestFn(`/admin/learning/lesson/${lessonId}`, 'POST', { ...data, _method: 'PUT' }, { formData: true }),
    deleteLessonForAdmin: ({ lessonId, ...data }) => requestFn(`/admin/learning/lesson/${lessonId}`, 'DELETE', data),

    courseMembersMassAction: ({ courseId, ...data }) => requestFn(`/admin/learning/course/${courseId}/members/massaction`, 'POST', data, { formData: true }),

    reorderLessonForAdmin: ({ courseVariantId, ...data }) => requestFn(`/admin/learning/course-variant/${courseVariantId}/reorder`, 'PUT', data),

    getCoursesAnalytics: data => requestFn('/admin/learning/analytics/courses/', 'GET', data),
    getCourseMembersAnalytics: ({ courseId, ...data }) => requestFn(`/admin/learning/analytics/courses/${courseId}/members`, 'GET', data),
    getCourseMembersAnalyticsDownload: ({ courseId, ...data }) => requestFn(`/admin/learning/analytics/courses/${courseId}/members/download`, 'GET', data, { blob: true }),
    getCourseLessonsAnalytics: ({ courseId, ...data }) => requestFn(`/admin/learning/analytics/courses/${courseId}/lessons`, 'GET', data),
    getCourseLessonsAnalyticsDownload: ({ courseId, ...data }) => requestFn(`/admin/learning/analytics/courses/${courseId}/lessons/download`, 'GET', data, { blob: true }),
    getCourseVariantQuestionsAnalytics: ({ courseVariantId, ...data }) => requestFn(`/admin/learning/analytics/course-variant/${courseVariantId}/questions`, 'GET', data),
    getLessonQuestionsAnalytics: ({ lessonId, ...data }) => requestFn(`/admin/learning/analytics/lessons/${lessonId}/questions`, 'GET', data),
    getMembersAnalytics: data => requestFn('/admin/learning/analytics/members/', 'GET', data),
    getMemberCoursesAnalytics: ({ memberId, ...data }) => requestFn(`/admin/learning/analytics/members/${memberId}/courses`, 'GET', data),
    getMemberCoursesAnalyticsDownload: ({ memberId, ...data }) => requestFn(`/admin/learning/analytics/members/${memberId}/courses/download`, 'GET', data, { blob: true }),

    getMemberCourseVariantAnalytics: ({ memberId, courseVariantId, ...data }) => requestFn(`/admin/learning/analytics/members/${memberId}/course-variant/${courseVariantId}`, 'GET', data),
    getMemberCourseVariantsAnalytics: ({ memberId, courseId, ...data }) => requestFn(`/admin/learning/analytics/members/${memberId}/courses/${courseId}/variants`, 'GET', data),

    getMemberCourseAnalytics: ({ memberId, courseId, ...data }) => requestFn(`/admin/learning/analytics/members/${memberId}/courses/${courseId}`, 'GET', data),
    getMemberCourseAnalyticsCsv: ({ memberId, courseId, ...data }) => requestFn(`/admin/learning/analytics/members/${memberId}/courses/${courseId}/csv`, 'GET', data, { blob: true }),
    getMemberCourseAnalyticsPdf: ({ memberId, courseId, ...data }) => requestFn(`/admin/learning/analytics/members/${memberId}/courses/${courseId}/pdf`, 'GET', data, { blob: true }),
    getLabelsAnalytics: data => requestFn('/admin/learning/analytics/labels/', 'GET', data),
    getLabelCoursesAnalytics: ({ labelId, ...data }) => requestFn(`/admin/learning/analytics/labels/${labelId}/courses`, 'GET', data),

    getCourseInternalTypeSuggestions: (data) => requestFn('/admin/learning/course-internal-type/suggestions', 'GET', data),
  },

  documents: {
    getFolders: data => requestFn('/document/folder/', 'GET', data, { schema: [documentFolderSchema] }),
    getFolder: ({ folderId, ...data }) => requestFn(`/document/folder/${folderId}`, 'GET', data, { schema: documentFolderSchema }),
    getSections: ({ folderVariantId, ...data }) => requestFn(`/document/folder-variant/${folderVariantId}/section`, 'GET', data, { schema: [documentSectionSchema] }),
    postSectionClick: ({ sectionId, ...data }) => requestFn(`/document/section/${sectionId}/track`, 'POST', data),

    reorderSectionsForAdmin: ({ folderVariantId, ...data }) => requestFn(`/admin/document/folder-variant/${folderVariantId}/reorder`, 'PUT', data),

    getFoldersForAdmin: data => requestFn('/admin/document/folder/', 'GET', data, { schema: [documentFolderSchema] }),
    getFolderForAdmin: ({ folderId, ...data }) => requestFn(`/admin/document/folder/${folderId}`, 'GET', data, { schema: documentFolderSchema }),
    postFolderForAdmin: data => requestFn('/admin/document/folder/', 'POST', data),
    putFolderForAdmin: ({ folderId, ...data }) => requestFn(`/admin/document/folder/${folderId}`, 'PUT', data),
    deleteFolderVariantForAdmin: ({ folderVariantId, ...data }) => requestFn(`/admin/document/folder-variant/${folderVariantId}`, 'DELETE', data),
    getSectionsForAdmin: ({ folderVariantId, ...data }) => requestFn(`/admin/document/folder-variant/${folderVariantId}/section`, 'GET', data, { schema: [documentSectionSchema] }),
    getSectionForAdmin: ({ sectionId, ...data }) => requestFn(`/admin/document/section/${sectionId}`, 'GET', data, { schema: documentSectionSchema }),
    postSectionForAdmin: ({ folderVariantId, ...data }) => requestFn(`/admin/document/folder-variant/${folderVariantId}/section`, 'POST', data, { formData: true, schema: documentSectionSchema }),
    putSectionForAdmin: ({ sectionId, ...data }) => requestFn(`/admin/document/section/${sectionId}`, 'POST', { ...data, _method: 'PUT' }, { formData: true, schema: documentSectionSchema }),
    deleteSectionForAdmin: ({ sectionId, ...data }) => requestFn(`/admin/document/section/${sectionId}`, 'DELETE', data),
  },
});

export const api = apiGenerator(request);
export const apiWithoutSchemas = apiGenerator((url, method, data, { schema, ...options } = {}, ...rest) => request(url, method, data, options, ...rest));

export function* apiCall(apiFn, payload, config = {}) {
  const data = yield apiFn(payload);
  const { ok, response, error } = data;

  if (ok && config.onSuccess) {
    config.onSuccess(response);
  } else if (!ok && config.onFailure) {
    config.onFailure(error);
  }

  if (config.onComplete) {
    config.onComplete(data);
  }

  return data;
}

export function* commonApiSaga({ apiFn, workspaceId = false, successMessage = false, failureMessage, onFailure, onSuccess }, { type, payload }) {

  const extraPayload = {
    ...(workspaceId && { workspaceId: yield select(authSelectors.getWorkspaceId) }),
  };

  const { ok, response, error } = yield apiCall(apiFn, { ...extraPayload, ...payload });

  if (ok) {
    yield put({
      type: `${type}_SUCCESS`,
      payload: {
        ...(response?.data ? response : { data: response }), // Don't mess up plain array responses etc
        request: payload,
      },
    });

    if (successMessage !== false) {
      yield put(snackbarActions.createSuccess(successMessage));
    }

    if (onSuccess) {
      yield call(onSuccess);
    }
  } else {
    yield put({ type: `${type}_FAILURE`, payload: { ...error, request: payload } });

    if (failureMessage !== false) {
      yield put(snackbarActions.createFailure(failureMessage ?? error.message));
    }

    if (onFailure) {
      yield call(onFailure);
    }
  }
}
