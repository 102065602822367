import combineReducers from 'combine-reducers-global-state';

import authTypes from './auth/types'; // Direct import, to avoid circular dependencies

import { entitiesReducers } from './entities';

import analyticsReducers from './analytics/reducers';
import authReducers from './auth/reducers';
import birthdayReducers from './birthday/reducers';
import boostReducers from './boost/reducers';
import calendarReducers from './calendar/reducers';
import chatReducers from './chat/reducers';
import courseReducers from './course/reducers';
import learningAnalyticsReducers from './learningAnalytics/reducers';
import lessonReducers from './lesson/reducers';
import questionReducers from './question/reducers';
import driveReducers from './drive/reducers';
import jobAnniversaryReducers from './jobAnniversary/reducers';
import trainingReducers from './training/reducers';
import challengeReducers from './challenge/reducers';
import channelReducers from './channel/reducers';
import commentReducers from './comment/reducers';
import contractReducers from './contract/reducers';
import crumbReducers from './crumb/reducers';
import feedbackReducers from './feedback/reducers';
import fileReducers from './file/reducers';
import groupReducers from './group/reducers';
import guidanceReducers from './guidance/reducers';
import integrationReducers from './integration/reducers';
import memberReducers from './member/reducers';
import pageReducers from './page/reducers';
import pulseReducers from './pulse/reducers';
import pollReducers from './poll/reducers';
import postReducers from './post/reducers';
import quizReducers from './quiz/reducers';
import userReducers from './user/reducers';
import workspaceReducers from './workspace/reducers';
import documentFolderReducers from './documentFolder/reducers';
import documentSectionReducers from './documentSection/reducers';

const appReducer = combineReducers({
  ...analyticsReducers,
  ...authReducers,
  ...birthdayReducers,
  ...boostReducers,
  ...calendarReducers,
  ...chatReducers,
  ...courseReducers,
  ...learningAnalyticsReducers,
  ...lessonReducers,
  ...questionReducers,
  ...driveReducers,
  ...trainingReducers,
  ...challengeReducers,
  ...channelReducers,
  ...commentReducers,
  ...contractReducers,
  ...crumbReducers,
  ...driveReducers,
  ...entitiesReducers,
  ...feedbackReducers,
  ...fileReducers,
  ...groupReducers,
  ...guidanceReducers,
  ...integrationReducers,
  ...jobAnniversaryReducers,
  ...memberReducers,
  ...pageReducers,
  ...pulseReducers,
  ...pollReducers,
  ...postReducers,
  ...quizReducers,
  ...userReducers,
  ...workspaceReducers,
  ...documentFolderReducers,
  ...documentSectionReducers,
});

export default (state, action) => {
  // On logout and failed-refresh clear all state
  switch (action.type) {
    case authTypes.logoutSuccess:
    case authTypes.refreshTokenFailure:
      return appReducer(undefined, action);
    default:
      break;
  }

  return appReducer(state, action);
};
