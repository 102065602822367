import { useTheme } from '@emotion/react';
import { useSession } from '@talkjs/react';
import uniq from 'lodash/uniq';
import { ChatContext } from 'providers/ChatProvider';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { chatSelectors, chatTypes } from 'redux/chat';
import { promiseListener } from 'redux/store';

class ConversationNotFoundError extends Error {}

const useChat = () => {

  const session = useSession();
  const theme = useTheme();
  const { t } = useTranslation('chat');

  const { setFloatingConversations } = useContext(ChatContext);
  const { openConversation, setOpenConversation } = useContext(ChatContext);
  const { onManageMembers, onToggleSize, onManageConversation } = useContext(ChatContext);

  const addFloatingConversation = useCallback((conversationId) => {
    setFloatingConversations(current => current.includes(conversationId)
      ? current
      : uniq([conversationId, ...current]));
  }, [setFloatingConversations]);

  const removeFloatingConversation = useCallback((conversationId) => {
    setFloatingConversations(current => current.filter(id => id !== conversationId));
    setOpenConversation(null);
  }, [setFloatingConversations, setOpenConversation]);

  const getConversationSelector = useSelector(chatSelectors.getConversationSelector);

  const fetchConversationPromise = useCallback((conversationId) => {
    const { asyncFunction } = promiseListener.createAsyncFunction({
      start: chatTypes.getConversation,
      resolve: chatTypes.getConversationSuccess,
      reject: chatTypes.getConversationFailure,
    });

    return asyncFunction({ conversationId });
  }, []);

  const getOrCreatePopup = useCallback((conversationId) => {
    return Promise.resolve((() => {
      const conversation = getConversationSelector(conversationId);

      return conversation?.id
        ? conversation
        : fetchConversationPromise(conversationId).then(
          ({ entities }) => {
            return entities?.conversations?.[conversationId];
          },
          ({ payload }) => {
            if (payload?.status === 404) {
              removeFloatingConversation(conversationId);
              throw new ConversationNotFoundError();
            }

            throw new Error(payload?.message);
          },
        );

    })())
      .then(conversation => {
        let popup = session.getPopups().find(p => p.conversation === conversationId);

        if (!popup) {
          popup = session.createPopup({
            launcher: 'never',
            showCloseInHeader: true,
            theme: {
              name: 'guavahr',
              custom: {
                primaryColor: theme.palette.primary.main,
                primaryColorDark: theme.palette.primary.dark,
                primaryColorLight: theme.palette.primary.light,
                primaryContrastText: theme.palette.primary.contrastText,

                displayManageMembers: conversation?.permissions?.manageMembers ? 'block' : 'none', // display: none|block;
                displayManageConversation: conversation?.permissions?.manage ? 'block' : 'none', // display: none|block;
                displayToggleSize: 'block', // display: none|block;

                // IMPORTANT! Translations need quotes around the strings!
                viewMembersTranslation: `"${t('actionMenu.viewMembers')}"`,
                toggleSizeTranslation: `"${t('actionMenu.toggleSize')}"`,
                manageConversationTranslation: `"${t('actionMenu.manageConversation')}"`,
              },
            },
          });
          popup.select(conversationId);
          popup.mount({ show: false });

          popup.onCustomConversationAction(e => {
            if (e.action === 'minimize') {
              onMinimizeConversation(conversationId);
            }
            if (e.action === 'view_members') {
              onManageMembers(conversationId);
            }
            if (e.action === 'toggle_size') {
              onToggleSize(conversationId);
            }
            if (e.action === 'manage_conversation') {
              onManageConversation(conversationId);
            }
          });

          popup.onClose(() => onCloseConversation(conversationId));
          popup.onOpen(() => onShowConversation(conversationId));
        }

        return popup;
      }).catch(e => {
        if (e instanceof ConversationNotFoundError) {
          return null;
        }
      });

  }, [getConversationSelector, fetchConversationPromise, removeFloatingConversation, session, theme.palette.primary.main, theme.palette.primary.dark, theme.palette.primary.light, theme.palette.primary.contrastText, t, onMinimizeConversation, onManageMembers, onToggleSize, onManageConversation, onCloseConversation, onShowConversation]);

  const onShowConversation = useCallback((conversationId) => {
    getOrCreatePopup(conversationId)
      .then(popup => {
        popup.show();

        addFloatingConversation(conversationId);
        setOpenConversation(conversationId);
      });
  }, [addFloatingConversation, getOrCreatePopup, setOpenConversation]);

  const onCloseConversation = useCallback((conversationId) => {
    getOrCreatePopup(conversationId)
      .then(popup => {
        popup.destroy();
      });

    removeFloatingConversation(conversationId);
    setOpenConversation(current => current === conversationId ? null : current);
  }, [getOrCreatePopup, removeFloatingConversation, setOpenConversation]);

  const onMinimizeConversation = useCallback((conversationId) => {
    setOpenConversation(current => current === conversationId ? null : current);

    getOrCreatePopup(conversationId).then(popup => popup.hide());

    addFloatingConversation(conversationId);
  }, [addFloatingConversation, getOrCreatePopup, setOpenConversation]);

  const onToggleConversation = useCallback((conversationId) => {
    if (openConversation === conversationId) {
      onMinimizeConversation(conversationId);
    } else {
      onShowConversation(conversationId);
    }
  }, [onMinimizeConversation, onShowConversation, openConversation]);

  const onSendConversationToTop = useCallback((conversationId) => {
    setFloatingConversations(current => [
      conversationId,
      ...current.filter(c => c !== conversationId),
    ]);
  }, [setFloatingConversations]);

  return useMemo(() => ({
    onShowConversation,
    onCloseConversation,
    onMinimizeConversation,
    onToggleConversation,
    onSendConversationToTop,
  }), [onCloseConversation, onMinimizeConversation, onSendConversationToTop, onShowConversation, onToggleConversation]);
};

export default useChat;
