import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Session, useSession } from '@talkjs/react';
import useFeature from 'hooks/useFeature';
import config from 'config';
import { useDispatch, useSelector } from 'react-redux';
import { chatActions, chatSelectors } from 'redux/chat';
import useLocalStorage from 'hooks/useLocalStorage';
import styled from '@emotion/styled/macro';
import Avatar from 'components/Avatar';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import useChat from 'hooks/useChat';
import { css, Global, useTheme } from '@emotion/react';
import ManageMembersDialog from 'containers/Chat/ManageMembersDialog';
import ManageConversationDialog from 'containers/Chat/ManageConversationDialog';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';

export const ChatContext = React.createContext({
  inbox: null,
  floatingConversations: [],
  openConversations: [],
});

const ChatStyles = ({ chatSize }) => {

  const theme = useTheme();

  return (
    <Global styles={css`
      [id*="__talkjs_popup_container"] {
        .__talkjs_popup {
          position: fixed; // Duplicated from external styles. Here to reduce initial page jumping
          z-index: ${theme.zIndex.chatPopups};
          right: 75px;
          bottom: 0px;

          @media (min-width: 420px) {
            height: calc(60vh - 77px); // Not sure why -77px. Was there originally
            width: 420px;
            max-width: calc(100vw - 125px);

            ${chatSize === 'large' && css`
              height: calc(80vh - 77px);
              width: 600px;
            `}
          }
        }
      }
    `}
    />
  );
};

ChatStyles.propTypes = {
  chatSize: PropTypes.oneOf(['large']),
};

const FloatingConversationsWrapper = styled('div')`
  position: fixed;

  display: flex;
  flex-direction: column;

  bottom: ${({ hasIntercom }) => hasIntercom ? 'calc(20px + 48px + 10px)' : '20px'};
  right: 20px;

  z-index: ${({ theme }) => theme.zIndex.chatPopups};

  /* TODO: Shitty selector? */
  & > * + * {
    margin-top: 10px;
  }
`;

const FloatingConversationCloseButton = styled(IconButton)`
  position: absolute;
  top: 0px;
  right: 0px;

  background: #fff;
  border: 1px solid #ddd;
  padding: 2px;

  display: none;

  &:hover {
    background: #ddd;
  }

  svg {
    width: 12px;
    height: 12px;
  }
`;

const FloatingConversationAvatar = styled(Avatar)`
  /* border: 2px solid #fff; */

  &:hover {
    transition: transform 250ms cubic-bezier(0.33, 0, 0, 1);
    transform: scale(1.1);
  }
`;

const FloatingConversationWrapper = styled('div')`
  position: relative;

  &:hover ${FloatingConversationCloseButton} {
    display: flex;
  }
`;

const MenuItemConversationName = styled('div')`
  font-weight: 500;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
`;

const FloatingConversation = ({ conversationId, idx }) => {

  const session = useSession();
  const dispatch = useDispatch();
  const { onToggleConversation, onCloseConversation } = useChat();

  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    if (!conversationId) {
      return;
    }
    dispatch(chatActions.getConversation({ conversationId }));
  }, [conversationId, dispatch]);

  const conversation = useSelector(chatSelectors.getConversationSelector)(conversationId);

  const fetchAndUpdateUnreadCount = useCallback(() => {
    session.conversation(conversationId).get().then(c => {
      if (!c) {
        return; // Required if/else?
      }
      setUnreadCount(c.unreadMessageCount);
    });
  }, [conversationId, session]);

  useEffect(() => {
    if (!session) {
      return;
    }

    fetchAndUpdateUnreadCount();
    session.onMessage(fetchAndUpdateUnreadCount);

    // TODO: Clear notifications on open!!
    // Actual chat context maybe:
    //    openConversation
    //    minimizeConversation (?)
    //    closeConversation
    //    toggleConversation
    //    ...and rest implementation details should be hidden?
  }, [conversationId, fetchAndUpdateUnreadCount, session]);

  return (
    <FloatingConversationWrapper>
      <Badge
        badgeContent={unreadCount}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        color="primary"
      >
        <FloatingConversationAvatar
          idx={idx}
          size={48}
          name={conversation?.subject}
          src={conversation?.photoUrl}
          title={conversation?.subject}
          onClick={() => onToggleConversation(conversationId)}
        />
      </Badge>
      <FloatingConversationCloseButton color="inherit" onClick={() => onCloseConversation(conversationId)}>
        <CloseRoundedIcon />
      </FloatingConversationCloseButton>
    </FloatingConversationWrapper>
  );
};

FloatingConversation.propTypes = {
  conversationId: PropTypes.any,
  idx: PropTypes.number,
};

const AggregatedFloatingConversations = ({ conversationIds }) => {

  const anchorEl = useRef();
  const [open, setOpen] = useState(false);

  const getConversationSelector = useSelector(chatSelectors.getConversationSelector);

  const { onToggleConversation, onSendConversationToTop } = useChat();

  const onMenuItemClick = (conversationId) => {
    setOpen(false);
    onToggleConversation(conversationId);
    onSendConversationToTop(conversationId);
  };

  return (
    <FloatingConversationWrapper>
      <Badge
        ref={anchorEl}
        onClick={() => setOpen(c => !c)}
        // badgeContent={unreadCount}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        color="primary"
      >
        <FloatingConversationAvatar size={48} sx={{ cursor: 'pointer' }}>
          +{conversationIds?.length ?? 0}
        </FloatingConversationAvatar>
      </Badge>
      <Menu
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorEl.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        PaperProps={{
          style: {
            transform: 'translateX(-10px)', // Offset 10px to the left
          },
        }}
      >
        {(conversationIds || [])
          .map(getConversationSelector)
          .filter(c => !!c)
          .map(conversation => (
            <MenuItem key={conversation.id} onClick={() => onMenuItemClick(conversation.id)}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Avatar size={16} src={conversation?.photoUrl} name={conversation?.subject} />
                <MenuItemConversationName>{conversation.subject ?? conversation.generatedSubject}</MenuItemConversationName>
              </Stack>
            </MenuItem>
          ))}
      </Menu>
    </FloatingConversationWrapper>
  );
};

AggregatedFloatingConversations.propTypes = {
  conversationIds: PropTypes.arrayOf(PropTypes.any),
};

const ChatProvider = (args) => {

  const dispatch = useDispatch();
  const chatFeature = useFeature('chat');

  const intercomFeature = useFeature('intercom');
  const intercomRight = !window.intercomSettings?.alignment || window.intercomSettings?.alignment === 'right';
  const hasIntercom = intercomFeature.enabled && intercomRight;

  const [floatingConversations, setFloatingConversations] = useLocalStorage('guava-floating-conversations', []);
  const [chatSize, setChatSize] = useLocalStorage('guava-floating-conversation-size', null);
  const [openConversation, setOpenConversation] = useState(null);
  const [manageMembersDialog, setManageMembersDialog] = useState(null);
  const [manageDialog, setManageDialog] = useState(null);

  const getConversationSelector = useSelector(chatSelectors.getConversationSelector);

  const onManageMembers = useCallback((conversationId) => {
    setManageMembersDialog(conversationId);
  }, []);

  const onManageConversation = useCallback((conversationId) => {
    setManageDialog(conversationId);
  }, []);

  const onToggleSize = useCallback(() => {
    setChatSize(c => c === 'large' ? null : 'large');
  }, [setChatSize]);

  const contextValue = useMemo(() => ({
    floatingConversations,
    setFloatingConversations,

    openConversation,
    setOpenConversation,

    onManageMembers,
    onToggleSize,
    onManageConversation,
  }), [floatingConversations, setFloatingConversations, openConversation, onManageMembers, onToggleSize, onManageConversation]);

  useEffect(() => {
    if (!chatFeature.enabled) {
      return;
    }

    dispatch(chatActions.getToken());
  }, [chatFeature.enabled, dispatch]);

  const initialized = useRef();
  useEffect(() => {
    if (initialized.current) {
      return;
    }

    // Load missing conversations
    const missingConversationIds = floatingConversations
      .filter(conversationId => !getConversationSelector(conversationId));

    dispatch(chatActions.getConversations({ conversationIds: missingConversationIds }));

    initialized.current = true;
  }, [dispatch, floatingConversations, getConversationSelector]);

  const chatToken = useSelector(chatSelectors.getToken);
  const chatUserId = useSelector(chatSelectors.getUserId);

  if (!chatFeature.enabled || !chatUserId || !chatToken) {
    return <React.Fragment {...args} />;
  }

  const all = floatingConversations
    .map(getConversationSelector)
    .filter(v => !!v)
    .map(c => c.id);

  const [head, tail] = all.length > 5
    ? [all.slice(0, 4), all.slice(4)]
    : [all, []];

  return (
    <Session
      appId={config.talkjs.appId}
      token={chatToken}
      userId={chatUserId}
    >
      <ChatStyles chatSize={chatSize} />
      <ChatContext.Provider value={contextValue}>
        <FloatingConversationsWrapper hasIntercom={hasIntercom}>
          {head.map((conversationId, idx) => (
            <FloatingConversation key={conversationId} conversationId={conversationId} idx={idx} />
          ))}
          {tail.length > 0 && <AggregatedFloatingConversations conversationIds={tail} />}
        </FloatingConversationsWrapper>
        <ManageMembersDialog conversationId={manageMembersDialog} open={!!manageMembersDialog} onClose={() => setManageMembersDialog(null)} />
        <ManageConversationDialog conversationId={manageDialog} open={!!manageDialog} onClose={() => setManageDialog(null)} />
        <React.Fragment {...args} />
      </ChatContext.Provider>
    </Session>
  );
};

export default ChatProvider;
