import React, { } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from 'components/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { chatActions, chatSelectors, chatTypes } from 'redux/chat';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import FormField from 'components/FinalForm/Fields/FormField';
import Form from 'components/FinalForm/Form';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import useFileInput from 'hooks/useFileInput';
import InputAdornment from '@mui/material/InputAdornment';
import DialogActions from '@mui/material/DialogActions';
import TextLoader from 'components/TextLoader';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';

const ManageConversationDialog = ({ conversationId, onClose, ...rest }) => {

  const dispatch = useDispatch();

  const conversation = useSelector(chatSelectors.getConversationSelector)(conversationId);

  const onChange = file => {
    if (!file) {
      return;
    }

    dispatch(chatActions.updateConversation({ conversationId, photo: file }));
  };

  const { open: onUploadPhotoClick } = useFileInput({
    onChange,
    accept: 'image/png,image/jpeg,.jpg',
  });

  const onDeletePhotoClick = () => {
    dispatch(chatActions.updateConversation({ conversationId, photo: 0 }));
  };

  return (
    <Dialog maxWidth="xs" fullWidth onClose={onClose} {...rest}>
      <DialogTitle>
        Manage conversation
      </DialogTitle>
      <DialogContent align="center">
        {!conversation && <TextLoader />}

        <Form
          start={chatTypes.updateConversation}
          resolve={chatTypes.updateConversationSuccess}
          reject={chatTypes.updateConversationFailure}

          hidden={!conversation}

          initialValues={{
            conversationId,
            subject: conversation?.subject,
          }}
        >
          {({ handleSubmit, dirty }) => (
            <Grid container direction="column" wrap="nowrap" spacing={4}>
              <Grid item>
                <Grid container direction="column" wrap="nowrap" spacing={2}>
                  <Grid item>
                    <Avatar
                      size={100}
                      src={conversation?.photoUrl}
                      name={conversation?.subject}
                    />
                  </Grid>
                  <Grid item>
                    <Grid container spacing={2} justifyContent="center">
                      <Grid item>
                        <Button size="small" color="default" startIcon={<CameraAltOutlinedIcon />} onClick={onUploadPhotoClick}>
                          Upload photo
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button size="small" color="default" startIcon={<DeleteOutlineRoundedIcon />} onClick={onDeletePhotoClick}>
                          Remove photo
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <FormField
                  name="subject"
                  margin="none"
                  placeholder="Name this chat..."
                  // sx={{ input: { textAlign: 'center' } }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment edge="end">
                        <Button variant="text" disabled={!dirty} onClick={handleSubmit} size="small">Save</Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Form>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" wrap="nowrap" alignItems="center" justifyContent="flex-end">
          <Grid item>
            <Button variant="text" onClick={onClose}>Done</Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

ManageConversationDialog.propTypes = {
  conversationId: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ManageConversationDialog;
