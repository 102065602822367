import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'react-final-form';
import withFlatArgs from 'components/FinalForm/withFlatArgs';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';
import Avatar from 'components/Avatar';
import ListItem from '@mui/material/ListItem';
import Autocomplete from 'components/Fields/Autocomplete/Autocomplete';
import Chip from '@mui/material/Chip';
import { workspaceSelectors } from 'redux/workspace';
import { useTranslation } from 'react-i18next';
import useReduxLoading from 'hooks/useReduxLoading';
import { memberActions, memberSelectors, memberTypes } from 'redux/member';

const listboxPadding = 8; // px

const Name = styled('div')`
  margin-left: 0.5rem;
`;

const StyledChip = ({ member, ...rest }) => !member ? null : (
  <Chip
    size="small"
    label={member.name}
    avatar={<Avatar src={member.avatar?.src} srcSet={member.avatar?.srcSet} name={member.name} size={16} />}
    {...rest}
  />
);

StyledChip.propTypes = {
  member: PropTypes.shape({
    avatar: PropTypes.shape({
      src: PropTypes.string,
      srcSet: PropTypes.string,
    }),
    name: PropTypes.string,
  }),
};

const renderRow = ({ data, index, style }) => {
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + listboxPadding,
  };

  if (Object.prototype.hasOwnProperty.call(dataSet, 'group')) {
    return (
      <ListSubheader key={dataSet.key} component="li" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  const [dataSetProps, member] = dataSet;

  return (
    <ListItem {...dataSetProps} key={dataSetProps.key} dense style={inlineStyle}>
      <Avatar size={20} src={member.avatar?.src} name={member?.name} />
      <Name>{member?.name}</Name>
    </ListItem>
  );
};

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(({ children, ...other }, ref) => {
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => Object.prototype.hasOwnProperty.call(child, 'group') ? 48 : itemSize;

  const getHeight = () => itemCount > 8
    ? 8 * itemSize
    : itemData.map(getChildSize).reduce((a, b) => a + b, 0);

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * listboxPadding}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
};

const nameSortFn = (a, b) => {
  if (!a.name || !b.name) {
    return 0;
  }

  const nameA = a.name.toLowerCase();
  const nameB = b.name.toLowerCase();

  // TODO: Hardcored locale.
  // If locale is undefined or incorrect, then it might consider ÖÕO etc
  // to be similar and produce an incorrect order for groupBy
  return nameA.localeCompare(nameB, 'et', { sensitivity: 'variant' });
};

const MemberAutocomplete = ({ options: providedOptions, loading: providedLoading, ...rest }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation('common');

  const [initialized, setInitialized] = useState(false);

  // TODO:
  // This component will not work well together with MemberSearchField as that filters the list
  // on server, which would cause issues in this component after initialization
  const getMemberSelector = useSelector(workspaceSelectors.getMemberSelector);

  const defaultOptions = useSelector(memberSelectors.getMemberIdsForSearchField);
  const options = (providedOptions !== undefined ? providedOptions : defaultOptions)
    .map(getMemberSelector)
    .sort(nameSortFn)
    .map(({ id }) => id);

  const getMember = useCallback((id) => getMemberSelector(id), [getMemberSelector]);
  const getMemberName = useCallback((id) => getMember(id)?.name, [getMember]);
  const getMemberInitial = useCallback((id) => getMemberName(id)?.[0]?.toUpperCase(), [getMemberName]);

  const groupBy = useMemo(() => options?.length > 200 ? getMemberInitial : null, [getMemberInitial, options?.length]);

  useEffect(() => {
    if (!initialized || providedOptions) {
      return;
    }
    dispatch(memberActions.getMembersForSearchField({ limit: 9999 }));
  }, [dispatch, initialized, providedOptions]);

  const defaultLoading = useReduxLoading(
    [memberTypes.getMembersForSearchField],
    [memberTypes.getMembersForSearchFieldSuccess, memberTypes.getMembersForSearchFieldFailure],
    true,
  );
  const loading = providedLoading !== undefined || options !== undefined ? providedLoading : defaultLoading;

  return (
    <Autocomplete
      disableListWrap
      size="small"
      ListboxComponent={ListboxComponent}
      options={options}
      groupBy={groupBy}
      renderOption={(props, option, state) => [props, getMember(option), state.index]}
      renderGroup={(params) => params}
      getOptionLabel={(option) => getMemberName(option) ?? ''}

      onOpen={() => setInitialized(true)}

      renderTags={(memberIds, getTagProps) => memberIds.map((memberId, index) => {
        const { key, ...tagProps } = getTagProps({ index });
        return <StyledChip member={getMember(memberId)} key={key} {...tagProps} />;
      })}

      noOptionsText={loading ? t('common:generic.loading') : null}

      {...rest}
    />
  );
};

MemberAutocomplete.propTypes = {
  options: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool,
};

const FinalFormComponent = withFlatArgs(MemberAutocomplete, 'MemberAutocomplete');
MemberAutocomplete.FinalForm = (args) => <Field component={FinalFormComponent} allowNull {...args} />;

export default MemberAutocomplete;
